import { Navigate } from 'react-router-dom';
import DashboardLayout from './components/DashboardLayout';
import MainLayout from './components/MainLayout';
import Account from './pages/Account';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import ProductList from './pages/ProductList';
import Register from './pages/Register';
import Settings from './pages/Settings';
import UsersList from './pages/UsersList';
import AddEditUser from './pages/AddEditUser';
import AddEditCustomer from './components/customer/AddEditCustomer';
import { CustomerDetails } from './pages/CustomerDetails';
import { AddEditLoan } from './pages/AddEditLoan';
import { LoanList } from './components/loans/LoanList';
import MyLoanList from './pages/MyLoanList';
import EMIList from './pages/emi-list';
import PaymentCreate from './pages/payment';

import  LoanDetails  from './components/loans/LoanDetails';
import Dataset from './pages/Dataset';
import CustomerAddScreen from './pages/customer/add-edit';
import ApplicantAddScreen from './pages/applicant/add-edit';
import CoApplicantAddScreen from './pages/co-applicant/add-edit';
import ApplicantListScreen from './pages/applicant/list';
import CoApplicantListScreen from './pages/co-applicant/list';
import CustomerListScreen from './pages/customer/list';
import ApplicantViewScreen from './pages/applicant/view';
import CoApplicantViewScreen from './pages/co-applicant/view';




const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'users', element: <UsersList /> },
      { path: 'users/add', element: <AddEditUser /> },
      { path: 'users/:id', element: <AddEditUser /> },
      { path: 'customers', element: <CustomerListScreen /> },
      { path: 'customers/details/:id', element: <CustomerDetails /> },
      { path: 'customers/add', element: <CustomerAddScreen /> },
      { path: 'customers/:id', element: <CustomerAddScreen /> },
      { path: 'customers/loan/add/:id', element: <AddEditLoan /> },
      { path: 'customers/loan/edit/:id', element: <AddEditLoan /> },
      { path: 'co-applicant/loan/add/:id', element: <AddEditLoan /> },
      { path: 'co-applicant/loan/edit/:id', element: <AddEditLoan /> },
      { path: 'loans', element: <LoanList /> },
      { path: 'my-loans', element: <MyLoanList /> },
      { path: 'loan/details/:id', element: <LoanDetails /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: 'dataset', element: <Dataset /> },
      { path: 'emi/:id', element: <EMIList /> },
      { path: 'payment/:id', element: <PaymentCreate /> },
      { path: 'applicants', element: <ApplicantListScreen /> },
      { path: 'applicant/add', element: <ApplicantAddScreen /> },
      { path: 'applicant/edit/:id', element: <ApplicantAddScreen /> },
      { path: 'applicant/details/:id', element: <ApplicantViewScreen /> },
      { path: 'coapplicant/details/:id', element: <CoApplicantViewScreen /> },
      // { path: 'co-applicant', element: <CoApplicantListScreen /> },
      // { path: 'co-applicant/add', element: <CoApplicantAddScreen /> },
      // { path: 'co-applicant/:id', element: <CoApplicantAddScreen /> },
      { path: '*', element: <Navigate to="/404" /> },
    ]
  }, {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: localStorage.getItem('token') ? <Navigate to="/app/customers" /> : <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }];

export default routes;
