import React, { useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Stack
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import _ from "lodash"
import { Fieldset } from '../shared/Fieldset';
import { useSelector } from 'react-redux';
import { notification } from 'antd';
function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}

function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item
      md={6}
      xs={10}
      lg={4}
    >
      <FormInputDropdown name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

const CustomerPersonalInfo = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
 
 
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  

  const { handleSubmit, control } = useForm({
    defaultValues: { ...customerInfo }
  });


  const PCOptions = [

    {
      label: 'No',
      value: 'no'
    },
    {
      label: 'Yes',
      value: 'yes'
    },
  ]

  
  const onSubmit = (data) => {
console.log("asjdjasjdsa",data);

    if(data?.maritalStatus=="married" && !data?.spouseName){
      notification.error({
        message:"Error",
        description:"Spouse name is required"
      })
    }else{
      setCustomerInfo({
        ...customerInfo, ...data
      });
      handleNext(false);
    }
    
  }
  const renderCommonList = (type) =>{
    if(type=="occupation"){
      let list = _.map(globalConfiguration[type], (value, key) => ({ value: key, label: value }));
      return list
    }else{
      let list = _.map(globalConfiguration?.customerOptions[type], (value, key) => ({ value: key, label: value }));
    return list
    }
    
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} >

      <Card>
        
        
        <CardContent>
        <Box sx={{margin:"24px"}}>
        <Fieldset
            margin="0px"
            // marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Personal Information
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
          <Grid container spacing={2} margin={"3px"}>
          <Grid container item spacing={2} mb={1}>
              <FormRow control={control} name="spouseName" label="Spouse name" rules={CustomerRules.spouseName} readonly={isNextActive} />
              <FormRow control={control} name="fathersName" label="Father's Name" rules={CustomerRules.fathersName} readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRow control={control} name="mothersName" label="Mother's name" rules={CustomerRules.mothersName} readonly={isNextActive} />
              <FormRowRadio
                name="education"
                control={control}
                label="Education"
                options={renderCommonList("educationOptions")} rules={CustomerRules.education} readonly={isNextActive}
              />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRowRadio
                name="maritalStatus"
                control={control}
                label="Maritial Status"
                options={renderCommonList("maritalStatus")} rules={CustomerRules.maritialStatus} readonly={isNextActive}
              />
              <FormRowRadio
                name="religion"
                control={control}
                label="Religion"
                options={renderCommonList("religionOptions")} rules={CustomerRules.religion} readonly={isNextActive}
              />

            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRowRadio
                name="caste"
                control={control}
                label="Caste"
                options={renderCommonList("casteOptions")} rules={CustomerRules.caste} readonly={isNextActive}
              />
              <FormRowRadio
                name="physicallyChallenged"
                control={control}
                label="Physically Challenged ?"
                options={PCOptions} rules={CustomerRules.physicallyChallenged} readonly={isNextActive}
              />
              {/* <Grid item
      md={6}
      xs={10}
      lg={4}
    >
      <div style={{
        fontSize: "15px",
        color: "#6b778c",
        fontFamily: "Roboto",
        fontWeight: "400"
      
      }}>Physically Challenged</div>
             <Switch checkedChildren="Yes" unCheckedChildren="No" defaultChecked /></Grid> */}

            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRowRadio
                name="occupation"
                control={control}
                label="Occupation"
                options={renderCommonList("occupation")} rules={CustomerRules.occupation} readonly={isNextActive}
              />


            
            </Grid>
          </Grid>
          </Fieldset>
          </Box>
        </CardContent>
        <Divider />
       <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button   variant="contained"
              sx={{ mt: 2, ml: 1 }} onClick={handleBack} >
                Previous
              </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
     
      </Card>
    </form>
  );
};


export default CustomerPersonalInfo;
