import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  Card,
  Divider,
  CardContent,
  Box,
  Stack,
  Grid,
} from "@mui/material";
import _ from "lodash";
import { useSelector } from "react-redux";
import { Fieldset } from "../shared/Fieldset";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FormInputDropdown } from "../shared/FormInputDropdown";
import { FormInputText } from "../shared/FormInputText";
import { useFieldArray, useForm } from "react-hook-form";
import "./style.css";
import { DocumentUploadFile } from "../shared/DocumentUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { services } from "../services/services";
import axios from "axios";
import { Image, message, Modal, Spin } from "antd";
import { CustomerRules } from "src/utils/validationsConfig";

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly, multiline = false } = props;
  return (
    <React.Fragment>
      <Grid item md={6} xs={10} lg={3}>
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          multiline={multiline}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={3}>
      <FormInputDropdown name={name} control={control} label={label} options={options} readonly={readonly || false} rules={rules} />
    </Grid>
  );
}
const DocumentDetails = ({
  handleBack,
  handleNext,
  customerDetail,
  setCustomerInfo,
  customerInfo
}) => {
  const {
    handleSubmit,
    trigger,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      documents: {
        addressDocuments: _.size(customerInfo?.documents?.addressDocuments)>0?
        _.map(customerInfo?.documents?.addressDocuments, (v) => ({ ...v, documentUpload: { name: v?.url } })): [
          { type: "", uniqueNo: "", documentUpload: null, url: "" },
        ],
        idDocuments:_.size(customerInfo?.documents?.idDocuments)>0?
        _.map(customerInfo?.documents?.idDocuments, (v) => ({ ...v, documentUpload: { name: v?.url } })): [{ type: "", uniqueNo: "", documentUpload: null, url: "" }],
      },
    },
  });
  const [messageApi, contextHolder] = message.useMessage();
  const [imageLoader, setimageLoader] = useState(false)
  const { fields: addressFields, append: appendAddress, remove: removeAddress } =
    useFieldArray({
      control,
      name: "documents.addressDocuments",
    });

  const { fields: idFields, append: appendId, remove: removeId } = useFieldArray({
    control,
    name: "documents.idDocuments",
  });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );

  const watchDocuments = watch("documents");

  const ViewImage = async (value) => {
    if (value) {
      
      
      try {
        const response = await services.getService(`file/get?key=${value}`);
        if (response?.presignedUrl) {
          console.log('asldmasdksa',value,response?.presignedUrl);
          
        window.open(response?.presignedUrl,"_blank");
          
          
          // return { imageUrl: response.presignedUrl, success: true };
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  const FormRowUpload = ({category, control, name, label, rules, id, defaultValue, image_Details, readonly, disabled, index ,extraDocument}) => {
    const handleUploadChange = (values) => {
      setimageLoader(true)
      postService(values, index,category);
    };

    console.log('asldmaskdsa',extraDocument);
    

    return (
      <React.Fragment>
        <Grid item md={6} xs={10} lg={2}>
          <DocumentUploadFile
            imageChange={handleUploadChange}
            id={id}
            show_upload={image_Details}
            readonly={readonly || false}
            defaultValue={defaultValue}
            newFieldTru={(va) => {
              handleUploadChange(va);
            }}
            onclickView ={()=>{
              ViewImage(extraDocument?.url)
            }}
            name={name}
            label={label}
            control={control}
            rules={rules}
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const handleAddRow =async (category) => {
    const isValid = await trigger(
      category === "addressDocuments"
        ? "documents.addressDocuments"
        : "documents.idDocuments" // Validate specific fields based on category
    );
  
  
    if (!isValid) {
      // Validation failed; don't append a new row
      return;
    }
    if (category === "addressDocuments") {
      appendAddress({
        type: "",
        uniqueNo: "",
        documentUpload: null,
        url: "",
      });
    } else if (category === "idDocuments") {
      appendId({
        type: "",
        uniqueNo: "",
        documentUpload: null,
        url: "",
      });
    }
  };

  const getAvailableDocumentTypes = (currentIndex, category) => {
    
    
    const selectedTypes =[...watchDocuments.addressDocuments.map((doc) => doc.type),...watchDocuments.idDocuments.map((doc) => doc.type)]
      


let addressDocuments = _.map(globalConfiguration?.customerOptions[category], (value, key) => ({ value: key, label: value }));
console.log("Log level", selectedTypes,addressDocuments,
  
);
    return addressDocuments.map(
      (type) =>{
        console.log("Lopp",(selectedTypes.includes(type.value) ||
        selectedTypes[currentIndex] === type.value),selectedTypes,type.value);
        
        return {...type,disabled: (selectedTypes.includes(type.value) ||
          selectedTypes[currentIndex] === type.value)?true:false}
      }
    );
  };

  const postService = async (service, index, category) => {
    let ext = _.split(_.get(service, "name"), ".")[1];
    let key = getValues(`documents.${category}.${index}.type`);
    try {
      let response = await services.postService(`file/upload`, {
        documentType: key,
        documentExtension: ext,
        id: 'temp',
      });
      if (response.presignedUrl && response.key) {
        console.log('asldlasdmsa',response.key);
        
        setValue(`documents.${category}.${index}.url`, response.key);
        await postFiles(service, response.presignedUrl);
      }
    } catch (err) {
      setimageLoader(false)
      console.error(err);
    }
  };

  const postFiles = async (f, url) => {
    const formData = new FormData();
    formData.append("file", f);

    axios({
      method: "put",
      url: url,
      data: f,
    })
      .then(async (res) => {
        setimageLoader(false)
        messageApi.open({
          type: 'success',
          content: 'Image Upload Success',
        });
      })
      .catch((err) => {
        setimageLoader(false)
      });
  };
console.log('asldmaskdksa',getValues());

  const renderDocumentFields = (fields, category, append, remove) => (
    <>
      {fields.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            marginBottom: "20px",
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container spacing={2}  alignItems="center">
            <FormRowRadio
              name={`documents.${category}.${index}.type`}
              control={control}
              label="Document Type"
              options={getAvailableDocumentTypes(index, category)}
              rules={{ required: CustomerRules.commonValidation("Document type") }}
            />
            <FormRow
              name={`documents.${category}.${index}.uniqueNo`}
              control={control}
              label="Document No"
              rules={{ required: CustomerRules.commonValidation("Document Number") }}
            />
            <FormRowUpload
            name={`documents.${category}.${index}.documentUpload`}
                        defaultValue={getValues(`documents.${category}.${index}.documentUpload`)}
                        control={control}
                        extraDocument={getValues(`documents.${category}.${index}`)}
                        index={index}
                        category={category}
                        rules={{ required: CustomerRules.commonValidation("Document file") }}
                        
                      />
            
          </Grid>
          {fields.length > 1 && (
            <IconButton onClick={() => remove(index)} color="primary">
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      ))}
    </>
  );

  const onSubmit = (data) => {
    
    setCustomerInfo({
      ...customerInfo, 
      documents: data?.documents
    })
    handleNext();
  };

  return (<Spin spinning={imageLoader} size="large">
    <Card sx={{ padding: "16px" }}>
      {contextHolder}
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
        <Fieldset
              margin="0px"
              marginTop="25px"
              title={
                <Stack direction="row" alignItems="center" gap={1}>
                  Address Documents
                </Stack>
              }
              color="#4984BF"
              titleSize="1.2rem"
              borderWidth={1.5}
              borderRadius={1}
            >
            {renderDocumentFields(
              addressFields,
              "addressDocuments",
              appendAddress,
              removeAddress
            )}
            <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Button
              onClick={() => handleAddRow("addressDocuments")}
              variant="contained"
            >
              Add Document
            </Button>
            </div>
          </Fieldset>

          <Fieldset
              margin="0px"
              marginTop="25px"
              title={
                <Stack direction="row" alignItems="center" gap={1}>
                 Id Documents
                </Stack>
              }
              color="#4984BF"
              titleSize="1.2rem"
              borderWidth={1.5}
              borderRadius={1}
            >
            {renderDocumentFields(
              idFields,
              "idDocuments",
              appendId,
              removeId
            )}
             <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Button onClick={() => handleAddRow("idDocuments")} variant="contained">
              Add Document
            </Button></div>
          </Fieldset>
        </CardContent>
        <div className="clear"></div>
<Divider sx={{marginBottom:'16px',marginTop:"16px"}} />
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}>
          <Button variant="contained" onClick={handleBack}>
            Previous
          </Button>
          <Button variant="contained" type="submit">
            Next
          </Button>
        </Box>
      </form>

      <Modal
        open={previewVisible}
        onCancel={() => setPreviewVisible(false)}
        centered
      >
        <Image src={imageUrl} alt="Preview" />
      </Modal>
    </Card></Spin>
  );
};

export default DocumentDetails;
