import {
  Box,
  // Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Grid,
  // IconButton,
  Select,
  MenuItem
} from '@mui/material';
import { Search as SearchIcon } from 'react-feather';
// import ArrowCircleRightSharpIcon from '@mui/icons-material/ArrowCircleRightSharp';
// import _ from 'lodash'
import BreadcrumbsComponent from '../Breadcrumbs';

const LoansListToolbar = ({
  handleChange,
  // handleSearch,type_loan='loan'
}) => {
  const breadCrumbsData =[{ name: 'Applications', path: '/app/loans' }]
  return (
  <Box>
    <div style={{flexDirection:'row',display:'flex',justifyContent:'space-between'}}> <BreadcrumbsComponent data={breadCrumbsData}/>
    <Box
      sx={{
        
      }}
    >
      {/* <Button
        color="primary"
        variant="contained"
        href={`/app/customers/add?type=${type_loan}`}
      >
        Add customer
      </Button> */}
    </Box></div>
    <Box sx={{ mt: 3 }}>
      <Card>
        <CardContent>
          <Grid container spacing={1}>
            <Grid item md={11}>
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <Box >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search with applicant id"
                      variant="outlined"
                      onChange={handleChange}
                      // name="aadharId"
                    />
                  </Box>
                </Grid>
                <Grid item md={3}>
                  <Box >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search with co-applicant id"
                      variant="outlined"
                      onChange={handleChange}
                      name="name"
                    />
                  </Box>
                </Grid>
                {/* <Grid item md={3}>
                  <Box >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search with Email"
                      variant="outlined"
                      onChange={handleChange}
                      name="email"
                      type="email"
                    />
                  </Box>
                </Grid> */}
                <Grid item md={3}>
                  <Box >
                    
                <Select
                name='status'
                 renderValue={(selected) => {
                   
                  if (!selected) {
                    return <div style={{color:'#9ea6b4'}}>Search with Status</div>;
                  }
      
                  return selected
                }}
                displayEmpty={true}
                onChange={handleChange}
                fullWidth
                                variant="outlined"
                >
   
    
   {/* pending_review|approved|rejected|sent_back|disbursed|closed|overdue */}
    <MenuItem  value={'Pending'}>Pending</MenuItem>
    <MenuItem value={'Approved'}>Approved</MenuItem>
    <MenuItem value={'Disbursed'}>Disbursed</MenuItem>
    <MenuItem value={'Rejected'}>Rejected</MenuItem>
    <MenuItem value={'Closed'}>Closed</MenuItem>
    <MenuItem value={'Sent back'}>Sent back</MenuItem>
  </Select></Box></Grid>
                {/* <Grid item md={3}>
                  <Box >
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon
                              fontSize="small"
                              color="action"
                            >
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        )
                      }}
                      placeholder="Search with Status"
                      variant="outlined"
                      onChange={handleChange}
                      name="status"
                    />
                  </Box>
                </Grid> */}
              </Grid>
            </Grid>
            {/* <Grid item md={1}>
              <Box>
                <IconButton onClick={handleSearch}>
                  <ArrowCircleRightSharpIcon fontSize="large" />
                </IconButton>
              </Box>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  </Box>
)};

export default LoansListToolbar;
