import { useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { services } from "./services";
async function refreshAccessToken(navigate) {
  try {
    const token = localStorage.getItem("token");

    // If no token exists, clear storage and redirect to login
    if (!token) {
      localStorage.clear();
      console.log("No token found. Redirecting to login...");
      navigate("/login"); // Replace with your login route
      return;
    }
    const response = await services.getService(`user/session/extend`,{})
    

    if (response?.status==true) {
//       const data = await response.json();
      const newToken = response?.token;

//       // Update the token in localStorage
      localStorage.setItem("token", newToken);

//       console.log("Access token refreshed:", newToken);
    } else {
      // If the token refresh fails, clear storage and redirect to login
      localStorage.clear();
      console.error("Failed to refresh token. Redirecting to login...");
      navigate("/login");
    }
  } catch (error) {
    // Handle unexpected errors
    localStorage.clear();
    console.error("Error refreshing token. Redirecting to login...", error);
    navigate("/login");
  }
}

// Hook to schedule token refresh and handle missing token
function useTokenRefresher(intervalInMinutes = 50) {
  const navigate = useNavigate();
  const intervalId = useRef(null);
  const location = useLocation(); // Get the current route
  useEffect(() => {
       const isOnLoginScreen = location.pathname === "/login"; // Adjust based on your login route
   
       if (!isOnLoginScreen) {
         // Immediately check for token on load
         refreshAccessToken(navigate);
   
         // Set up the interval to refresh the token
         intervalId.current = setInterval(() => {
           refreshAccessToken(navigate);
         }, intervalInMinutes * 60 * 1000); // Convert minutes to milliseconds
       }
   
       // Clear the interval on unmount
       return () => {
         if (intervalId.current) clearInterval(intervalId.current);
       };
     }, [intervalInMinutes, navigate, location.pathname]);
   
}

export default useTokenRefresher;
