import React, { useEffect, useState } from "react";
import { FormInputDropdown } from "../shared/FormInputDropdown";
import { FormInputText } from "../shared/FormInputText";
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Stack,
  Typography,
  Skeleton
} from "@mui/material";

import { useForm } from "react-hook-form";
// import { Typography } from "antd";
import { useSelector } from "react-redux";
import _ from "lodash";
import { LoanRules } from "src/utils/validationsConfig";
import { FormAutoCompleteDropdown } from "../shared/FormAutoCompleteDropdown";
import { Fieldset } from "../shared/Fieldset";
import { Spin } from "antd";
function FormRow(props) {
  const {
    control,
    name,
    label,
    rules,
    inputType,
    readonly,
    multiline = false,
    rows=1
  } = props;
  return (
    <React.Fragment>
      <Grid item md={6} xs={10} lg={4}>
        <FormInputText
        rows={rows}
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          multiline={multiline}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormInputDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}

function FormRowAutoComplete(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item md={6} xs={10} lg={4}>
      <FormAutoCompleteDropdown
        name={name}
        control={control}
        label={label}
        options={options}
        readonly={readonly || false}
        rules={rules}
      />
    </Grid>
  );
}


const businessPremises = [
  {
    label: "Rented",
    value: "Rented",
  },
  {
    label: "Owned",
    value: "Owned",
  },
  {
    label: "Leased",
    value: "Leased",
  },
];



const LoanType = (props) => {
  
  
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const { handleSubmit, control, watch, reset,setValue,getValues } = useForm({
    defaultValues:{applicantIdMongo:"",coApplicantIdMongo:"" ,coApplicant:"", ...props?.loanInfo},
    values:{...props?.loanInfo}
  });
  const selectedLoanType = watch("loanProduct");
  const selectTypeOfIndustry = watch("productSpecificDetails.industryType");
  
const [coApplicantList, setcoApplicantList] = useState([])
  const [loanProduct, setloanProduct] = useState([]);
  const [typeOfIndustry, settypeOfIndustry] = useState([]);
  useEffect(() => {
    if (_.get(globalConfiguration, "loanProducts")) {
      let loanType = _.map(
        _.get(globalConfiguration, "loanProducts"),
        (value, key) => ({ value: key, label: value })
      );
      setloanProduct(loanType);
    }
    if (_.get(globalConfiguration, "loanProducts")) {
      let list = _.map(
        _.get(globalConfiguration, "industryTypes"),
        (value, key) => ({ value: key, label: value })
      );
      settypeOfIndustry(list);
    }
    
  }, []);


  useEffect(() => {

    if(_.size(_.get(props,'customerDetail.coApplicants'))>0){
      // - (${v?.customerId})
      let list=  _.map(_.get(props,'customerDetail.coApplicants'),(v)=>({ 
         label:`${ _.startCase(v?.firstName+' '+v?.lastName)}`,
         value: v?._id,
         id:v?._id
       }))
       setcoApplicantList(list)
       
       
       
       list.length ==1 &&
         setValue('coApplicant', list[0].value)
         setValue('coApplicantIdMongo', list[0].id)
      
         }
         if(props?.customerDetail?._id){
          setValue('applicantIdMongo', props?.customerDetail?._id)
         }
        
    
  }, [props?.customerDetail])
  

  const onSubmit = (data) => {
    
    
    
    props?.setloanInfo({...props?.loanInfo,...data})
    props?.handleNext(true)
  };

  const getSubCategory = (type) => {
    
    
        let list = _.map(
            _.get(globalConfiguration, `industrySubCategory[${type}]`,[]),
            (value, key) => ({ value: key, label: value })
          );
         
      return _.size(list)>0? list:[];
    
  };


  useEffect(() => {
    
    
   setTimeout(() => {
    setValue("productSpecificDetails.industryTypeSub",null)
   }, 1000);
    
  }, [selectTypeOfIndustry])
  

  const getPurposeLoan = (type) => {
        let list = _.map(
            _.get(globalConfiguration, `loanPurposes[${type}]`,[]),
            (value, key) => ({ value: key, label: value })
          );
         
      return _.size(list)>0? list:[];
    
  };
    
  const getLoanTenure = () => {
    let list = _.map(
        _.get(globalConfiguration, `loanTenure`,[]),
        (value, key) => ({ value: key, label: value })
      );
     
  return _.size(list)>0? list:[];

};
const getoccupation = () => {
  let list = _.map(
      _.get(globalConfiguration, `occupation`,[]),
      (value, key) => ({ value: key, label: value })
    );
   
return _.size(list)>0? list:[];

};

const getTypeLiveStock = () => {
  let list = _.map(
      _.get(globalConfiguration, `livestockType`,[]),
      (value, key) => ({ value: key, label: value })
    );
   
return _.size(list)>0? list:[];

};

  useEffect(() => {
    if (selectedLoanType) {
      
      // reset({product: selectedLoanType, 
      //   coApplicant:getValues('coApplicant'),
      //   coApplicantIdMongo:watch("coApplicantIdMongo"),
      //   applicantIdMongo:watch("applicantIdMongo"),
      //   },
      //   {
      //     keepErrors: false,
      //     keepDirty: false,
      //     keepValues: false,
      //     keepDefaultValues: false,
      //   } // Keeps errors but resets other field values
      // );
    }
  }, [selectedLoanType]);

  const filterLoanProduct = (loanType) => {
    let product = _.filter(
      loanProduct,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };


  return !props?.customerDetail?._id ? <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:"9%"}}>
    <Spin size="large" />
  </div>:
    <form onSubmit={handleSubmit(onSubmit)}>
    
   
  <Card
    sx={{
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      // backgroundColor: "#f9f9fc",
      padding: "16px",
    }}
  >
    {/* Applicant Details Section */}
    
        <CardContent>
        <Grid container spacing={2} sx={{marginLeft:'0px',width:"100%",paddingTop:'10px'}}>
        <Fieldset
                title={
                  <Stack direction="row" alignItems="center" gap={1}>
                    Applicant Details
                  </Stack>
                }
                color="#4984BF"
                titleSize="1.2rem"
                borderWidth={1.5}
                borderRadius={1}
                margin="10px"
              >
        <Box display="flex" flexDirection="row"  gap={4} sx={{
          padding:'16px',
          paddingBottom:'10px',
          paddingTop:"26px"
        }}>
   <Box display="flex" flexDirection="row" gap={4} sx={{marginTop:'-8px'}}>
   <Box display="flex" alignItems="flex-start"  flexDirection={'column'}>
        <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
          Applicant Name:
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
          {props?.ApplicantName}
        </Typography>
      </Box>
      {/* {<Box display="flex" alignItems="flex-start" flexDirection={'column'}>
        <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
        Applicant Id:
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
        {props?.customerDetail?.customerId?props?.customerDetail?.customerId:props?.customerDetail?.applicantId}
        </Typography>
      </Box>} */}
   </Box>
 

   <FormRowRadio
                name="coApplicantIdMongo"
                control={control}
                label="Co-Applicant"
                options={coApplicantList}
                rules={LoanRules.coApplicant}
              />


    </Box></Fieldset></Grid>
    <Grid container spacing={2} sx={{marginLeft:'0px',width:"100%"}}>
            <Grid
              container
              item
              spacing={2}
              sx={{ display: "flex", alignItems: "center",marginTop:'20px',marginBottom:"20px",paddingTop:'0px !important',
                marginLeft:'-4px !important',width:"100%",
                paddingLeft:"0px !important"
              }}
            >
              <FormRowRadio
                name="loanProduct"
                control={control}
                label="Loan Product"
                options={loanProduct}
                rules={LoanRules.loanType}
              />

              {selectedLoanType &&<Box display="flex" alignItems="flex-start" flexDirection={'column'} marginLeft={5}>
      <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
          Product Code:&nbsp;
        </Typography>
        <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000" }}>
          {selectedLoanType}
        </Typography>
      </Box>}
            </Grid>
            {selectedLoanType && (
              <Fieldset
                title={
                  <Stack direction="row" alignItems="center" gap={1}>
                    {filterLoanProduct(selectedLoanType)}
                  </Stack>
                }
                color="#4984BF"
                titleSize="1.2rem"
                borderWidth={1.5}
                borderRadius={1}
                  margin="10px"
                  marginTop="10px"
               
              >
               <Box sx={{
                 padding:'16px',
          paddingBottom:'10px'
               }}>
               {selectedLoanType === "SEL" && (
                  < >
                    <Grid container item spacing={2} mb={3} mt={0}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.industryType"
                        control={control}
                        label="Type of Industry"
                        options={typeOfIndustry}
                        rules={LoanRules.typeOfIndustry}
                      />
                      {selectTypeOfIndustry == "others" ? (
                        <FormRow
                          rules={LoanRules.subCategory}
                          control={control}
                          name="productSpecificDetails.industryTypeSub"
                          label="Sub Category"
                        />
                      ) : (
                        _.size(getSubCategory(selectTypeOfIndustry)) > 0 && (
                          <FormRowAutoComplete
                            name="productSpecificDetails.industryTypeSub"
                            control={control}
                            label="Sub Category"
                            rules={LoanRules.subCategory}
                            options={getSubCategory(selectTypeOfIndustry)}
                          />
                        )
                      )}
                      <FormRow
                        control={control}
                        name="productSpecificDetails.loanPurpose"
                        rules={LoanRules.businesExperience}
                        label="Purpose of loan"
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessName"
                        rules={LoanRules.tradeName}
                        label="Business Name"
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.experience"
                        rules={LoanRules.businesExperience}
                        label="Busines experience"
                        inputType="number"
                      />

                      <FormRow
                        control={control}
                        name="productSpecificDetails.location"
                        rules={LoanRules.LocationOfOperation}
                        label="Location of operation"
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.yearsOfOperation"
                        label="Years of operation"
                        rules={LoanRules.yearsOfOperation}
                      />

                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.totalInvestment"
                        label="Total Investment"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.womenEmployeeCount"
                        label="No of women employee"
                        rules={LoanRules.noOfWomenEmployee}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.totalEmployeeCount"
                        label="Total employee"
                        rules={LoanRules.totalEmployee}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessPAN"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.licenseNumber"
                        label="License No"
                        // rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.gstnumber"
                        label="GST No"
                        // rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessEmail"
                        label="Email"
                        rules={LoanRules.email}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.businessContactNumber"
                        label="Business Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.mobileNumber"
                        label="Mobile No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      rows={4}
                      multiline={true}
                        control={control}
                        name="productSpecificDetails.address"
                        label="Address"
                        rules={LoanRules.address}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.pincode"
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "WCL" && (
                  <>
                    <Grid container item spacing={2} paddingTop={"16px"} mb={3}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.industryType"
                        control={control}
                        label="Type of Industry"
                        options={typeOfIndustry}
                        rules={LoanRules.typeOfIndustry}
                      />
                      {selectTypeOfIndustry == "others" ? (
                        <FormRow
                          rules={LoanRules.subCategory}
                          control={control}
                          name="productSpecificDetails.industryTypeSub"
                          label="Sub Category"
                        />
                      ) : (
                        <FormRowAutoComplete
                          name="productSpecificDetails.industryTypeSub"
                          control={control}
                          label="Sub Category"
                          rules={LoanRules.subCategory}
                          options={getSubCategory(selectTypeOfIndustry)}
                        />
                      )}
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessName"
                        rules={LoanRules.tradeName}
                        label="Business Name"
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.experience"
                        rules={LoanRules.businesExperience}
                        label="Busines experience"
                      />

                      <FormRow
                        control={control}
                        name="productSpecificDetails.location"
                        label="Location of operation"
                        rules={LoanRules.LocationOfOperation}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.yearsOfOperation"
                        label="Years of operation"
                        rules={LoanRules.yearsOfOperation}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.totalInvestment"
                        label="Total Investment"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.womenEmployeeCount"
                        label="No of women employee"
                        rules={LoanRules.noOfWomenEmployee}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.totalEmployeeCount"
                        label="Total employee"
                        rules={LoanRules.totalEmployee}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessPAN"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.licenseNumber"
                        label="License No"
                        // rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.gstnumber"
                        label="GST No"
                        rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                   
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessEmail"
                        label="Email"
                        rules={LoanRules.email}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.businessContactNumber"
                        label="Business Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.mobileNumber"
                        label="Mobile No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      rows={4}
                      multiline={true}
                        control={control}
                        name="productSpecificDetails.address"
                        label="Address"
                        rules={LoanRules.address}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.pincode"
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "GL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.loanPurpose"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.landArea"
                        label="Land Size in Acres"
                        rules={LoanRules.acres}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.landValue"
                        label="Land Value"
                        rules={LoanRules.landValue}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.location"
                        label="Location of operation"
                        rules={LoanRules.location}
                      />
                       <FormRow
                       inputType="number"
                        control={control}
                        name="productSpecificDetails.experience"
                        label="Business experience"
                        rules={LoanRules.businesExperience}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.surveyNumber"
                        label="Survey No"
                        rules={LoanRules.sureveyNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      
                      <FormRow
                        control={control}
                        name="productSpecificDetails.subDivision"
                        label="Sub Division"
                        rules={LoanRules.subDivision}
                      />
                       <FormRowRadio
                          name="productSpecificDetails.ownershipType"
                          control={control}
                          label="Ownership"
                          options={businessPremises}
                          rules={LoanRules.ownerShip}
                        />
                    </Grid>
                   
                  </>
                )}
                {selectedLoanType === "LF" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.loanPurpose"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.location"
                        label="Location of operation"
                        rules={LoanRules.LocationOfOperation}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.businessPremises"
                        control={control}
                        label="Business Premises"
                        options={businessPremises}
                        rules={LoanRules.businessPremises}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.totalInvestment"
                        label="Total Investment"
                        inputType="number"
                        rules={LoanRules.totalInvestment}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.livestockType"
                        control={control}
                        label="Type of Livestock"
                        options={getTypeLiveStock()}
                        rules={LoanRules.typeOfLivestock}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.numberOfLivestock"
                        label="No of Livestock"
                        rules={LoanRules.noOfLivestock}
                      />
                    </Grid>

                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.livestockValue"
                        label="Live Stock Value"
                        rules={LoanRules.liveStockValue}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.annualIncome"
                        label="Annual Income"
                        rules={LoanRules.annualIncome}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.otherIncome"
                        label="Other Income"
                        rules={LoanRules.otherIncome}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.businessPAN"
                        label="Pan card No"
                        rules={LoanRules.panCardNo}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.licenseNumber"
                        label="License No"
                        // rules={LoanRules.licenseNo}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.gstnumber"
                        label="GST No"
                        rules={LoanRules.gstNo}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      
                       <FormRow
                       inputType="number"
                        control={control}
                        name="productSpecificDetails.businessContactNumber"
                        label="Business Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.mobileNumber"
                        label="Mobile No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.email"
                        label="Email"
                        rules={LoanRules.email}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      rows={3}
                        control={control}
                        name="productSpecificDetails.address"
                        multiline={true}
                        label="Address"
                        rules={LoanRules.address}
                      />
                      
                      
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                     
                       <FormRow
                        control={control}
                        name="productSpecificDetails.pincode"
                        multiline={true}
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                      
                    </Grid>
                  </>
                )}
                {selectedLoanType === "PL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.loanPurpose"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.employerCategory"
                        control={control}
                        label="Company Category"
                        options={getoccupation()}
                        rules={LoanRules.companyCategory}
                      />
                      <FormRow
                        control={control}
                        name="productSpecificDetails.employerName"
                        label="Comapny Name"
                        rules={LoanRules.companyName}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.designation"
                        label="Designation"
                        rules={LoanRules.designation}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.experience"
                        label="Year Of Experience"
                        rules={LoanRules.yearOfExperience}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.annualIncome"
                        label="Annual Salary"
                        rules={LoanRules.annualSalary}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                    <FormRow
                        control={control}
                        name="productSpecificDetails.managerName"
                        label="Manager Name"
                        rules={LoanRules.designation}
                      />
                       <FormRow
                       inputType="number"
                        control={control}
                        name="productSpecificDetails.managerContactNumber"
                        label="Manager Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.employerContactNumber"
                        label="Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      
                      
                     
                     
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                    <FormRow
                        control={control}
                        name="productSpecificDetails.employerEmail"
                        label="Email"
                        rules={LoanRules.email}
                      />
                      </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                      rows={4}
                      multiline={true}
                        control={control}
                        name="productSpecificDetails.address"
                        label="Address"
                        rules={LoanRules.address}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.pincode"
                        label="Pincode"
                        rules={LoanRules.pinCode}
                      />
                    </Grid>
                  </>
                )}
                {selectedLoanType === "CDL" && (
                  <>
                    <Grid container item spacing={2} mb={3} paddingTop={"16px"}>
                      <FormRowAutoComplete
                        name="productSpecificDetails.loanPurpose"
                        control={control}
                        label="Purpose of loan"
                        options={getPurposeLoan(selectedLoanType)}
                        rules={LoanRules.purposeOfLoan}
                      />
                    </Grid>
                    <Grid container item spacing={2} mb={3}>
                      <FormRow
                        control={control}
                        name="productSpecificDetails.loanAmount"
                        label="Loan Amount"
                        inputType="number"
                        rules={LoanRules.loanAmount}
                      />
                      <FormRowRadio
                        name="productSpecificDetails.loanTenure"
                        control={control}
                        label="Loan Tenure"
                        options={getLoanTenure()}
                        rules={LoanRules.loanTenure}
                      />
                      <FormRow
                        control={control}
                        inputType="number"
                        name="productSpecificDetails.businessContactNumber"
                        label="Business Contact No"
                        rules={LoanRules.phoneNo}
                      />
                      <FormRow
                      inputType="number"
                        control={control}
                        name="productSpecificDetails.mobileNo"
                        label="Mobile No"
                        rules={LoanRules.phoneNo}
                      />
                    </Grid>
                  </>
                )}
               </Box>
              </Fieldset>
            )}
          </Grid>
        </CardContent>
         {/* Action Buttons */}
         <Divider />
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingTop="16px"
      paddingLeft={"16px"}
      paddingRight={"16px"}
    >
     <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            href="/app/customers"
            style={{ height: "36px" }}
          >
            Cancel
          </Button>
      <Button
        variant="contained"
        sx={{
          height: "36px",
          background: "linear-gradient(90deg, #0066cc, #0055a5)",
          color: "white",
        }}
        type="submit"
      >
        Next
      </Button>
    </Box>
  </Card>
    </form>

};

export default LoanType;
