import React, { useEffect, useRef, useState } from "react";
import { FaUser, FaHome, FaUsers, FaUniversity, FaFileAlt, FaBuilding, FaIdCard } from "react-icons/fa";
import { Box, List, ListItem, ListItemIcon, ListItemText, Card, Grid, Typography, Avatar, CardContent, Divider } from "@mui/material";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { services } from "src/components/services/services";
import moment from "moment";
import { Button, Space, Table } from "antd";
import BreadcrumbsComponent from "src/components/Breadcrumbs";

const tabs = [
  { id: "1", label: "Applicant Details", icon: <FaUser /> },
  { id: "2", label: "Personal Info", icon: <FaIdCard /> },
  { id: "3", label: "Address Details", icon: <FaHome /> },
  { id: "4", label: "Family Details", icon: <FaUsers /> },
  { id: "5", label: "Bank Details", icon: <FaUniversity /> },
  { id: "6", label: "Reference Details", icon: <FaUsers /> },
  { id: "7", label: "Document Details", icon: <FaFileAlt /> },
  // { id: "7", label: "Co-applicant", icon: <FaBuilding /> }
];



// Material UI styling
const useStyles = makeStyles({
  sidebar: {
    width: "28%", // Increased the sidebar width
    // padding: "16px",
    borderRight: "none",
    paddingTop:"16px" // Removed the right border here
  },
  content: {
    display: "flex",
    flexDirection: "row",
    padding: "24px",
    paddingTop:"16px",
    overflowY: "auto",
    position: "relative",
    gap: "24px", // Adding gap between left and right container
    width: "69%",
  },
  tabCard: {
    marginBottom: "16px",
    padding: "16px",
    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#ffffff", // Card background color set to white
  },
  tabActive: {
    backgroundColor: "white",
    color: "#333",
    border: "2px solid #e0e0e0", // Light grey border for the active tab
    boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow for the active tab
    borderRadius: "4px", // Slightly rounded corners for better visual appeal
    transition: "transform 0.3s ease", // Adding a transition effect
    transform: "scale(1.05)", // Slightly scale up the tab when active for effect
  },
  tabInactive: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
  },
  waveEffect: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    height: "4px",
    backgroundColor: "#3f51b5",
    animation: "waveAnimation 0.6s ease-in-out",
  },
  "@keyframes waveAnimation": {
    "0%": { transform: "scaleX(0)", transformOrigin: "left" },
    "50%": { transform: "scaleX(1)", transformOrigin: "right" },
    "100%": { transform: "scaleX(0)", transformOrigin: "left" },
  },
  contentField: {
    paddingBottom: "8px", // Reduced the spacing between content fields
  },
  tabIconText: {
    display: "flex",
    alignItems: "center",
    gap: "8px", // 8px gap between icon and label
  },
  profileImage: {
    width: "170px", // Set the size of the image to 170x170px
    height: "170px",
    borderRadius: "8px", // Slight rounding for visual appeal
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Light shadow for the image
    marginBottom: "16px", // Add margin below the image
  },
  leftContainer: {
    flex: 0.9, // 70% width for left container
  },
  rightContainer: {
    flex: 0.3, // 30% width for right container
    display: "flex",
    justifyContent: "center", // Center the image
    alignItems: "center", // Center the image vertically
  },
});

export default function Profile(props) {
  const [activeTab, setActiveTab] = useState("1");
  const [showWaveEffect, setShowWaveEffect] = useState(false); // For controlling the wave effect
  const classes = useStyles();
  const [loading, setloading] = useState(false);
  const globalConfiguration = useSelector((state) => state?.globalConfiguration?.data);
  let { id } = useParams();
  const [customerDetail, setcustomerDetail] = useState({});
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const [previewVisible, setPreviewVisible] = useState(false);
  const previewRef = useRef();
  const [imageUrl, setimageUrl] = useState("");
  const location = useLocation();
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    setShowWaveEffect(true);
    setTimeout(() => setShowWaveEffect(false), 600); // Stop the wave effect after the animation duration
  };
  useEffect(() => {
    if (id) {
      getCustomerDetail(id);
    }
  }, []);
  const formatIndianPhoneNumber = (phoneNumber) => {
    // Remove non-numeric characters
    const digits = phoneNumber.replace(/\D/g, "");

    // Format the number as 987-654-3210
    return `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(6)}`;
  };
  const handleViewDocument = (url) => {
    // Handle view document functionality (e.g., open in a new window or modal)
    window.open(url, "_blank");
  };

  const handleDownloadDocument = (url) => {
    // Handle download document functionality
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").pop();
    link.click();
  };
  const getCustomerDetail = (id) => {
    services
      .getService(`coapplicant/details/${id}`)
      .then((res) => {
        setcustomerDetail(res?.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const fetchImageData = async () => {
      if (customerDetail?.photo) {
        const data = await ViewProfileImage(customerDetail?.photo);
        

        setImageData(data);
      }
    };

    fetchImageData();
  }, [customerDetail?.photo]);
  const ViewProfileImage = async (value) => {
    if (value) {
      

      try {
        const response = await services.getService(`file/get?key=${value}`);
        if (response?.presignedUrl) {
          return { imageUrl: response.presignedUrl, success: true };
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };
  const formatAadhaarNumber = (aadhaarNumber) => {
    // Remove non-numeric characters
    const digits = aadhaarNumber.replace(/\D/g, "");

    // Check if the number is valid (12 digits for Aadhaar)

    // Format the number as XXXX XXXX XXXX
    return `${digits.slice(0, 4)} ${digits.slice(4, 8)} ${digits.slice(8)}`;
  };
  const ViewImage = async (value) => {
    try {
      let response = await services.getService(`file/get?key=${value}`);
      

      if (response?.presignedUrl) {
        window.open(response?.presignedUrl, "_blank");
        // setimageUrl(response?.presignedUrl)
        // setPreviewVisible(true)
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
      }
      throw err;
    }
  };
  const renderApplicantDetails=(type)=>{
    
    
    let details =
      type == "applicant"
        ? ["firstName", "lastName",  "dob", "gender", "email","mobileNumber"]
        : ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];
    return  <Box sx={{ display: "flex", gap: "24px" }}>
    {/* Left Section (Applicant Details) */}
    <Box className={classes.leftContainer}>
      <Grid container spacing={2}> {/* Reduced spacing between fields */}
        {/* Left Side (Applicant Details) */}
        {_.map(_.pick(customerDetail, details), (value, key) => (
          key !== "profileImage" && (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Box className={classes.contentField}>
                <Typography variant="body2" sx={{ color: "#3255a3" }}>
                  {key == "dob"?"DOB":_.startCase(key)}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333" }}>
                {key == "dob"
                        ? _.isObject(value) == true
                          ?moment(value).format("DD/MM/YYYY")
                          : moment(value).format("DD/MM/YYYY")
                        : key == "mobileNumber"
                        ? `+91 ${value}`
                        : key == "aadharId"
                        ? formatAadhaarNumber(value)
                        :key == "email"?value: _.startCase(value)}
                </Typography>
              </Box>
            </Grid>
          )
        ))}
      </Grid>
    </Box>

    {/* Right Section (Profile Image) */}
    {imageData?.imageUrl &&<Box className={classes.rightContainer}>
      <Avatar
        alt="Profile Image"
        src={imageData?.imageUrl}
        className={classes.profileImage}
      />
    </Box>}
  </Box>
  }


  const renderProduct =()=>{
     
    let details = ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];
    return  <Box sx={{ display: "flex", gap: "24px" }}>
    {/* Left Section (Applicant Details) */}
    <Box className={classes.leftContainer}>
      <Grid container spacing={2}> {/* Reduced spacing between fields */}
        {/* Left Side (Applicant Details) */}
        {_.map(_.pick(customerDetail, details), (value, key) => (
          key !== "profileImage" && (
            <Grid item xs={12} sm={6} md={4} key={key}>
              <Box className={classes.contentField}>
                <Typography variant="body2" sx={{ color: "#3255a3" }}>
                  {_.startCase(key)}
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "bold", color: "#333" }}>
                {key == "dob"
                        ? _.isObject(value) == true
                          ? moment(value).format("DD/MM/YYYY")
                          : moment(value).format("DD/MM/YYYY")
                        : key == "mobileNumber"
                        ? formatIndianPhoneNumber(value)
                        : key == "aadharId"
                        ? formatAadhaarNumber(value)
                        :key == "email"?value: _.startCase(value)}
                </Typography>
              </Box>
            </Grid>
          )
        ))}
      </Grid>
    </Box>

    
  </Box>
  }


  const renderAddress =()=>{
    let addressDetails = _.get(customerDetail,'addressDetails')
    const formatAddress = (address) => {
      if (!address) return "N/A";
  
      const {
        houseNumber,
        addressLine1,
        addressLine2,
        cityVillageTown,
        district,
        state,
        pinCode,
        noOfYears,
        noOfMonths,
      } = address;
  
      // Format the duration string
      const duration = [];
      if (noOfYears > 0) duration.push(`${noOfYears} Year(s)`);
      if (noOfMonths > 0) duration.push(`${noOfMonths} Month(s)`);
  
      return [
        houseNumber ? `NO: ${houseNumber}, ${addressLine1},` : null,
        addressLine2 ? `${addressLine2},` : null,
        cityVillageTown ? `${cityVillageTown},` : null,
        district ? `${district},` : null,
        state || pinCode ? `${state ? state : ""} - ${pinCode ? pinCode : ""}.` : null,
        duration.length > 0 ? `${duration.join(" and ")}` : null,
      ].filter(Boolean); // Removes null or undefined values
    };
  
    return <Box display="flex" flexDirection="column" gap={2} p={2}>
   <Typography variant="body2" sx={{ color: "#3255a3" }}>
      Present Address
    </Typography>
    <Grid container spacing={2} sx={{marginLeft:"1px"}}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  sx={{ marginRight: "16px", marginBottom: "16px" }}
                >
                  {formatAddress(addressDetails?.present).map((line, index) => (
                    <Typography
                      key={index}
                      sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                    >
                      {line}
                    </Typography>
                  ))}
                </Box>
              </Grid>
   {addressDetails?.permanentSameAsPresent==false && <> <Divider sx={{ my: 1 }} />
    <Typography variant="body2" sx={{ color: "#3255a3" }}>
      Permanent Address
    </Typography>
    <Grid container spacing={2} sx={{marginLeft:"1px"}}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  flexDirection="column"
                  sx={{ marginRight: "16px", marginBottom: "16px" }}
                >
                  {formatAddress(addressDetails?.permanent).map((line, index) => (
                    <Typography
                      key={index}
                      sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                    >
                      {line}
                    </Typography>
                  ))}
                </Box>
              </Grid></>}
  </Box>
  }
  function formatIndianNumber(value) {
    if (!_.isNumber(value)) return value;

    const number = _.toString(value);
    const lastThree = number.slice(-3);
    const rest = number.slice(0, -3);

    return rest.length > 0 ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
  }
  const renderFamily = (details, type) => {
    const fieldsToRender = [
          "name",
          "age",
          "gender",
          "relationship",
          "occupation",
          "income",
          "mobileNumber",
          "address",
        ];
        const formattedData = details?.map((item) => _.pick(item, fieldsToRender));
        console.log('asdmmaksdksa',formattedData);
        
    return _.map(formattedData, (item, index) => {
   const { address, ...otherDetails } = item;
      return (
        <Box key={index}>
          <Grid container spacing={2}>
            {_.map(otherDetails, (value, key) => (
              <Grid item xs={12} sm={6} md={3} key={key}>
                <Box display="flex" flexDirection="column" sx={{ mb: 2 }}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#3255a3" }}>
                    {_.startCase(key)}
                  </Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}>
                    {key === "income"
                      ? `₹${value.toLocaleString()}`
                      : key === "mobileNumber"
                      ? `+91 ${value}`
                      : _.startCase(value)}
                  </Typography>
                </Box>
              </Grid>
            ))}
            {/* Address moved to last */}
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column" sx={{ mb: 2 }}>
                <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>Address</Typography>
                <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}>
                  {address}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {index < details.length - 1 && <Divider sx={{ my: 2 }} />}
        </Box>
      );
    });
  };

  const renderBank = (data, type) => {
    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(data?.bankDetails, (value, key) => {
            return (
              <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                {" "}
                <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#3255a3" }}>{_.startCase(key)}</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                    {(key !="ifscCode" &&key !="accountNumber")? _.startCase(value):value}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };
   //render Document
   const renderDocument = (data) => {
    return (
      <>
        {_.map(data?.documents, (item, key, index) => {
          

          return (
            <Grid container key={index} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#3255a3", textDecorationLine: "underline", marginBottom: "15px" }}>
                  {_.startCase(key)}
                </Typography>
              </Box>
              <Box sx={{flexDirection:'row',display:'flex'}}>
                            <Box sx={{ display: "flex",flexDirection:'column' }}width={"100%"} >
                              {_.map(item, (v, i, index) => {
                                delete v?.documentUpload;
              return <Box key={index} flexDirection={"row"} display={"flex"} >
                                { _.map(v, (items, i) => {
                                  return (
                                    <Box key={i} display={"flex"} flexDirection={"row"} width={"100%"}>
                                      <Grid item  sm={6} md={3} width={"100%"} display={"flex"} flexDirection={"row"}>
                                      {" "}
                                      <Box display="flex" alignItems="flex-start"  flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "8px" }}>
                                        <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>
                                          {i == "url" ? "Attachment" : _.startCase(i)}
                                        </Typography>
                                        {i == "url" ? (
                                          <Typography
                                            component={"span"}
                                            onClick={() => {
                                              ViewImage(items);
                                            }}
                                            sx={{
                                              fontSize: "16px",
                                              fontWeight: "500",
                                              color: "#00000",
                                              boxShadow: "0 4px 8px #0000001a",
                                              border: "1px solid #eaecf0",
                                              padding: "2px 12px",
                                              borderRadius: "7px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            View
                                          </Typography>
                                        ) : (
                                          <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                                            {i == "type" ? _.startCase(items) : items}
                                          </Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                    </Box>
                                  );
                                })}
                                </Box>
                              })}
                            </Box>
                            </Box>
              <Divider />
            </Grid>
          );
        })}
      </>
    );
  };
  const columns = [
              
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
           title: 'Last Name',
           dataIndex: 'lastName',
           key: 'lastName',
         },
    {
      title: 'Mobile Number',
      dataIndex: 'mobileNumber',
      key: 'mobileNumber',
    },
    {
      title: 'Actions',
      dataIndex: 'action',
      key: 'action',
      render: (key, record) => {
      return  <Space size="middle">
         <Button onClick={()=>{
          navigate(`/app/coapplicant/details/${record?._id}`,{state:{
            customerDetail:`${_.startCase(customerDetail?.firstName)} ${_.startCase(customerDetail?.lastName)}`
          }})
         }}>View</Button>
         <Button 
          href={`/app/applicant/add?applicant=${customerDetail?._id}&coapplicant=${record?._id}`}
         >Edit</Button>
        </Space>
      },
    },
   
  ];
  const renderCoApplicant =()=>{
              
    let sampleList =_.get(customerDetail,"coApplicants")
          return _.size(_.get(customerDetail,"coApplicants"))>0?<div>
                 <Table dataSource={sampleList} columns={columns} pagination={false} />
                 <div style={{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
<Button
    variant="contained"
    sx={{
      height: "36px",
      background: "linear-gradient(90deg, #0066cc, #0055a5)",
      color: "white",
    }}
   href={`/app/applicant/add?applicant=${customerDetail?._id}`}
  >
    Add Co-applicant
  </Button>
</div>
          </div>:
           <div>
<Table dataSource={[]} columns={columns}  pagination={false} />
<div style={{marginTop:'10px',display:'flex',alignItems:'center',justifyContent:'center'}}>
<Button
    variant="contained"
    sx={{
      height: "36px",
      background: "linear-gradient(90deg, #0066cc, #0055a5)",
      color: "white",
    }}
   href={`/app/applicant/add?applicant=${customerDetail?._id}`}
  >
    Add Co-applicant
  </Button>
</div>
           </div>
    }
    let breadCrumbsData = [];

    breadCrumbsData =
      props?.customer_type == "applicant"
        ? [
            { name: location.state?.customerDetail?location.state?.customerDetail:"Applicants", path: `/app/applicant/details/${customerDetail?.applicantId}` },
            { name: "Co-applicant view", path:  `/app/coapplicant/details/${id}` },
          ]
        : [
          { name: "Applicants", path:  `/app/applicants` },
          { name: location.state?.customerDetail?location.state?.customerDetail:"Applicants", path:  `/app/applicant/details/${customerDetail?.applicantId}` },
            { name: "Co-applicant view", path: `/app/coapplicant/details/${id}` },
          ];
  
  return (
    <Box sx={{paddingLeft:'24px',marginTop:"24px"}}>
       {<BreadcrumbsComponent data={breadCrumbsData} />}
 
    <Box sx={{ display: "flex", height: "100vh", backgroundColor: "#f7fafc" }}>
      {/* Sidebar */}
     
      <Box className={classes.sidebar}>
        <Card className={classes.tabCard}>
          <List>
            {tabs.map((tab) => (
              <ListItem
                button
                key={tab.id}
                onClick={() => handleTabClick(tab.id)}
                className={`${activeTab === tab.id ? classes.tabActive : classes.tabInactive}`}
              >
                <ListItemIcon sx={{ color: activeTab === tab.id?"#3255a3":"black" }}>{tab.icon}</ListItemIcon>
                <ListItemText
                  primary={tab.label}
                  sx={{
                    color: "black",
                    display: "flex",
                    alignItems: "center",
                    gap: "8px", // 8px gap between icon and label
                  }}
                />
              </ListItem>
            ))}
          </List>
        </Card>
      </Box>

      {/* Content */}
      <Box className={classes.content}>
        <Card sx={{ padding: 3, boxShadow: 3, backgroundColor: "#ffffff", width: "100%" }}>
          {showWaveEffect && <div className={classes.waveEffect}></div>}
          {activeTab === "1" && (renderApplicantDetails("applicant")
           
          )}
          {activeTab === "2" && (renderProduct("applicant"))}

{activeTab === "3" && (renderAddress("applicant"))}
{activeTab === "4" && (renderFamily(customerDetail?.familyDetails))}
{activeTab === "5" && (renderBank(customerDetail))}
{activeTab === "6" && (renderFamily(customerDetail?.familyDetails))}
{activeTab === "7" && (renderDocument(customerDetail))}
{/* {activeTab === "7" && (renderCoApplicant(customerDetail))} */}
          {/* Additional sections can be added here dynamically based on activeTab */}
        </Card>
      </Box>
    </Box>   </Box>
  );
}
