
// Define the base API URLs for different environments
const apiUrls = {
    int: "https://api.thangamcapital.com/int/",
    qa: "https://api.thangamcapital.com/qa/",
    dev: "https://api.thangamcapital.com/dev/"
  };
  


  const currentDomain = window.location.hostname;
  
  // Determine the API URL based on the domain
  let apiUrl = "";
  if (currentDomain.includes("intadmin")) {
    apiUrl = apiUrls.int;
  } else if (currentDomain.includes("qaadmin")) {
    apiUrl = apiUrls.qa;
  } else if (currentDomain.includes("devadmin") || currentDomain.includes("localhost")) {
    apiUrl = apiUrls.dev;
  } else {
    apiUrl = apiUrls.dev;
  }
  

  
const globalConfig = {
    states: {
        "puduchery": "Puducherry",
        "tamilnadu": "TamilNadu",
        "kerala": "Kerala",
        "karnataka": "Karnataka",
        "Telegana": "Telegana",
        "Andhra Pradesh": "Andhra Pradesh"
    },
    relationshipList: {
        "husban": "Husband",
        "wife": "Wife",
        "father": "Father",
        "mother": "Mother",
        "son": "Son",
        "Daughter": "Daughter",
        "Brother": "Brother",
        "sister": "Sister",
        "friend": "Friend",
        "relatives": "Others Relative"
    },
    url: {
        baseDomain: apiUrl,
        // baseDomain: "http://localhost:3000/",
        apiVersion: "v2/"
    },
    loanAttributes: {
        "pending_review": "Pending Review",
        "approved": "Approved",
        "rejected": "Rejected",
        "sent_back": "Sent Back",
        "disbursed": "Disbursed",
        "closed": "Closed"
    },
    loan_purpose: {
        green: "Green Loan",
        sakthi_entrepreneurship: "Sakthi_Entrepreneurship Loan",
        working_capital: "Working Capital Loan",
        Live_stock_farming: "Live Stock Farming Loan",
        personal: "Personal loan",
        customer_durable: "Customer Durable Loan"
    }
}

export default globalConfig;