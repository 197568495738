import React, { useEffect, useState } from 'react';
import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Stack
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { PastInputDate } from '../shared/FormInputDate';
import { services } from '../services/services';
import _ from 'lodash'
import './style.css'
import { useNavigate } from 'react-router';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import { Fieldset } from '../shared/Fieldset';
import { UserProfileCardImage } from '../shared/DocumentUpload';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { message, notification, Spin } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={6}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
        />
      </Grid>
    </React.Fragment>
  );
}
const CustomerAccountInfo = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive,
  customerLoading
}) => {
const [imageLoading, setimageLoading] = useState(false)
 
  
  const FormRowUpload = ({
    control,
    name,
    label,
    rules,
    id,
    defaultValue,
    image_Details,
    readonly,
    disabled,
    index,
  }) => {
    const handleUploadChange = (values) => {
      setimageLoading(true)
      postService(values, index);
    };
   
    return (
      <React.Fragment>
        <Grid item md={6} xs={10} lg={4}>
          <UserProfileCardImage
            imageChange={handleUploadChange}
            show_upload={image_Details}
            readonly={false}
            defaultValue={defaultValue}
            newFieldTru={(va) => {
              handleUploadChange(va);
            }}
            name={name}
            label={label}
            control={control}
            rules={rules}
            disabled={disabled}
          />
        </Grid>
      </React.Fragment>
    );
  };

  const initialErrorState = {
    severity: '',
    message: '',
    error: false
  }
  const [imageUrl, setImageUrl] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [apiError, setApiError] = useState(initialErrorState);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
  const { handleSubmit, control, reset,getValues,setValue,watch } = useForm({
    defaultValues: { ...customerInfo }
  });
  const fieldValue = watch('photo');
const renderCommonList = (type) =>{
  let list = _.map(globalConfiguration?.customerOptions[type], (value, key) => ({ value: key, label: value }));
  return list
}

  const onSubmit = (data) => {
    
   if(!data?.photo){
    notification.error({
      message:"Error",
      description:"Profile image is required"
    })
   }else{
    setCustomerInfo({
      ...customerInfo, ...data
    });
    handleNext(false);
   }
    
    // if (Object.keys(customerInfo).length === 0) {
    //   services.postService(`customer/search`, { aadharId: data.aadharId })
    //     .then(res => {
    //       console.log('datsdsa', res)
    //       if (res?.length === 0 || res.total === 0) {
    //         let date = data?.dob?.toISOString().split("T")[0];
    //         data.dob = date;
    //         
    //         setCustomerInfo({
    //           ...customerInfo, ...data
    //         });
    //         handleNext(false);
    //       } else {
    //         setOpen(true);
    //         setApiError({
    //           severity: "error",
    //           message: `Validation Error :: Aadhar number & Email already exists.`,
    //           error: true,
    //           id: _.get(res, 'data[0].id')
    //         })
    //       }
    //     })
    //     .catch(err => {
    //       if (!localStorage.getItem('token')) {
    //         navigate('/login');
    //       }
    //     })
    // } else {
    //   setCustomerInfo({
    //     ...customerInfo, ...data
    //   });
    //   handleNext(false);
    // }
  }

  const handleClose = () => {
    setOpen(false);
    setApiError(initialErrorState)
  };

  React.useEffect(() => {
    !apiError.error && reset(customerInfo)
  }, [customerInfo])

  const postService = async (service, index) => {
    
    let ext = _.split(_.get(service, "name"), ".")[1];
   
    try {
      let response = await services.postService(`file/upload`, {
        documentType: 'profile',
        documentExtension: ext,
        id: "customers",
      });
      
      if (response.presignedUrl && response.key) {
        
        console.log('dsajjkshdkjsa',response.key);
       
        setValue(`photo`, response.key);
        await postFiles(service, response.presignedUrl);
      } else {
        
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      setimageLoading(false)
      let errors = err?.data?.results;
      if (errors) {
       
      }
      throw err;
    }
  };

  const postFiles = async (f, url) => {
    const formData = new FormData();
    formData.append("file", f);
    
    axios({
      method: "put",
      url: url,
      data: f,
    })
      .then(async (res) => {
        setimageLoading(false)
        messageApi.open({
          type: 'success',
          content: 'Image Upload Success',
        });
      })
      .catch((err) => {
        setimageLoading(false)
      });
  };
  useEffect(() => {
    const fetchImage = async () => {
      const value = getValues('photo'); // Get your value
      
      
      setTimeout(async() => {
        const imageData = await ViewImage(value); // Resolve the promise
      
      console.log('askdlasjlkdkasl',imageData);
      
      setImageUrl(imageData || null);
      }, 800); // Set the resolved image URL
    };

    fetchImage();
  }, [fieldValue]);
  const ViewImage = async (value) => {
    if (_.isString(value)) {
      
      
      try {
        const response = await services.getService(`file/get?key=${value}`);
        if (response?.presignedUrl) {
          console.log('adfasdlksajkdlas',response);
          
          return { imageUrl: response.presignedUrl, success: true };
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };
  return (
    <Spin spinning={imageLoading || customerLoading} size='large'>
    <>{contextHolder} <form onSubmit={handleSubmit(onSubmit)} >
        <Card>
          <CardContent >
        <Box sx={{margin:"24px"}}>
        <Fieldset
            margin="0px"
            // marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Applicant Details
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
          <Box sx={{display:'flex',alignItems:'flex-start'}}>
          <Grid container spacing={2} padding={"20px"} sx={{width:"80%"}}>
              <Grid container  item spacing={2} mb={1} sx={{width:"90%"}} >
                <FormRow control={control}  name="firstName" label="First Name" rules={CustomerRules.firstName} />
                <FormRow control={control} name="lastName" label="Last Name" rules={CustomerRules.lastName} />
              </Grid>
              <Grid container item spacing={2} mb={1} sx={{width:"90%"}}>
                <FormRow control={control} inputType="number" name="mobileNumber" label="Mobile number" rules={CustomerRules.mobileNumber} />
                <FormRow control={control} name="email" label="Email" rules={CustomerRules.email} inputType="email" />
              </Grid>
              <Grid container item spacing={2} mb={1} sx={{width:"90%"}}>
                <FormRow control={control} name="aadharId" label="Aadhar ID" inputType="number" rules={CustomerRules.aadharId} />

                <Grid item
        md={6}
        xs={10}
        lg={6} sx={{width:"90%"}}
      >
                  <PastInputDate minDate={dayjs("1880-01-01")} maxDate={dayjs("2008-12-31")} control={control} name="dob" label="DOB" rules={CustomerRules.dob} required={true} validation={true}/>    </Grid>
              </Grid>

              <Grid container item spacing={2} mb={1} sx={{width:"90%"}}>
                <Grid item
                  md={6}
                  xs={10}
                  lg={6}
                >
                  <FormInputDropdown name="gender" control={control} label="Gender" options={renderCommonList("gender")} rules={CustomerRules.gender} />
                  
                </Grid>
              </Grid>
            </Grid>
            <Box
                container
                item
                spacing={2}
                mb={3}
                mt={3}
                sx={{ }}
              >
                 <FormRowUpload
                    name={`photo`}
                    defaultValue={imageUrl}
                    label="Photo"
                    formType="formView"
                    control={control}
                    disabled={false}
                    // rules={{
                    //   required:
                    //     CustomerRules.commonValidation("Profile Image"),
                    // }}
                  />
              </Box>
          </Box>
            </Fieldset>
        </Box>
          </CardContent>
          <Divider />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              href="/app/customers"
              style={{ height: '36px' }}
            >
              Cancel
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 2, pb: 2 }}>
              {activeStep !== 0 && (
                <Button onClick={handleBack} sx={{ mt: 2, ml: 1 }}>
                  Previous
                </Button>
              )}

              <Button
                variant="contained"
                sx={{ mt: 2, ml: 1 }}
                type="submit"
              >
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </Box>
        </Card>
      </form>
    </></Spin>
  );
};


export default CustomerAccountInfo;
