import React, { useState } from 'react';

import {
  Button,
  Box,
  Card,
  CardHeader,
  Divider,
  CardContent,
  Grid,
  Stack,
} from '@mui/material';
import _ from 'lodash'
import globalConfig from "../../utils/config";

import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import { Fieldset } from '../shared/Fieldset';
import { Segmented, Switch } from 'antd';
import { useSelector } from 'react-redux';

function FormRow(props) {
  const { control, name, label, rules, inputType, readonly } = props;
  return (
    <React.Fragment>
      <Grid item
        md={6}
        xs={10}
        lg={4}
      >
        <FormInputText
          name={name}
          control={control}
          label={label}
          rules={rules}
          inputType={inputType || "text"}
          readonly={readonly || false}
          helperText={props.helperText || null}
          setAddressInfo={props.setAddressInfo}
          addressInfo={props.addressInfo}
        />
      </Grid>
    </React.Fragment>
  );
}
function FormRowRadio(props) {
  const { control, name, label, options, rules, readonly } = props;
  return (
    <Grid item
      md={6}
      xs={10}
      lg={4}
    >
      <FormInputDropdown name={name}
        control={control}
        label={label}
        options={options}
        rules={rules}
        readonly={readonly || false}
      />
     
    </Grid>
  );
}

const AddressDetails = ({
  handleBack,
  handleNext,
  activeStep,
  steps,
  setCustomerInfo,
  customerInfo,
  isNextActive
}) => {
  const [checkSwitch, setcheckSwitch] = useState(customerInfo?.addressDetails?.permanentSameAsPresent==true?true:false)
  const globalConfiguration = useSelector(
    (state) => state?.globalConfiguration?.data
  );
 

  const years = Array.from({ length: 50 }, (_, i) => ({
    value: i ,
    label: `${i}`,
  }));

  const months = Array.from({ length: 12 }, (_, i) => ({
    value: i,
    label: `${i}`,
  }));


  const { handleSubmit, control, reset, watch,getValues,setValue } = useForm({
    defaultValues: {addressDetails: {...customerInfo.addressDetails} },
  });

  

  const renderCommonList = (type) =>{
    if(type=="states"){
      let list = _.map(globalConfiguration[type], (value, key) => ({ value: key, label: value }));
      
      
      return list
    }else{
      let list = _.map(globalConfiguration?.customerOptions[type], (value, key) => ({ value: key, label: value }));
    return list
    }
    
  }

  const onSubmit = (data) => {
    
    setCustomerInfo({
      ...customerInfo, ...data
    })
    handleNext();
  }


const onChangeSwitch =(checked)=>{
  setcheckSwitch(checked)
  setValue("addressDetails.permanentSameAsPresent",checked)
  setValue("addressDetails.permanent.sameAsPermanent",checked)
  let fieldslist =["houseNumber",
    "addressLine1",
    "addressLine2",
    "cityVillageTown",
    "district",
    "pinCode",
    "landmark",
    "state",
    "noOfYears",
    "noOfMonths"]
 if(checked==false){
  
   _.map(fieldslist,(v,i)=>{
     setValue(`addressDetails.permanent.${[v]}`, "");
   })
  
   
 }else{
  _.map(fieldslist,(v,i)=>{
    
    
    let getFieldValue = getValues(`addressDetails.present.${[v]}`)
    
    setValue(`addressDetails.permanent.${[v]}`, getFieldValue);
  })
 }
}
  return (
    <form onSubmit={handleSubmit(onSubmit)} >
      <Card>
       
        <CardContent>
        <Box sx={{margin:"24px"}}>
        <Fieldset
            margin="0px"
            // marginTop="25px"
            title={
              <Stack direction="row" alignItems="center" gap={1}>
                Address Details
              </Stack>
            }
            color="#4984BF"
            titleSize="1.2rem"
            borderWidth={1.5}
            borderRadius={1}
          >
          <Box sx={{display:'flex',alignItems:'flex-start'}}>
          <Grid container spacing={2} margin={"3px"}>
       
            <div style={{
        fontSize: "16px",
        color: "#23286c",
        fontFamily: "Roboto",
        fontWeight: "600",marginBottom:'4px',
        marginLeft:'14px',
        marginTop:'14px'
        // textDecorationLine:'underline'
      
      }}>Present Address</div>
          <Grid container item spacing={2} mb={1}>
              <FormRowRadio
                name="addressDetails.present.residenceType"
                control={control}
                label="Residence Type"
                options={renderCommonList("residentOptions")} rules={CustomerRules.residenceType} readonly={isNextActive}
              />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRow control={control} name="addressDetails.present.houseNumber"  rules={{
                      required:
                        CustomerRules.commonValidation("House Number"),
                    }} label="House Number" readonly={isNextActive} />
              <FormRow control={control} 
               rules={{
                required:
                  CustomerRules.commonValidation("Address1"),
              }}
              name="addressDetails.present.addressLine1" label="Address1" readonly={isNextActive} />
             
            </Grid>
            <Grid container item spacing={2} mb={1}>
            <FormRow control={control} 
             
            name="addressDetails.present.addressLine2" label="Address2" readonly={isNextActive} />
            <FormRow control={control}
            
            rules={{
              required:
                CustomerRules.commonValidation("City/Town/Village"),
            }}name="addressDetails.present.cityVillageTown" label="City/Town/Village" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              
              
              <FormRow control={control} 
               rules={{
                required:
                  CustomerRules.commonValidation("District"),
              }}name="addressDetails.present.district" label="District" readonly={isNextActive} />
              <FormRow control={control} 
               rules={{
                required:
                  CustomerRules.commonValidation("Landmark"),
              }}name="addressDetails.present.landmark" label="Landmark" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
            <Grid item md={6} xs={10} lg={4} >
                <FormInputDropdown  rules={{
                      required:
                        CustomerRules.commonValidation("State"),
                    }} name="addressDetails.present.state" control={control} label="State" options={renderCommonList("states")} readonly={isNextActive} />
              </Grid>
              <FormRow control={control} inputType="number" rules={CustomerRules.pinCode} name="addressDetails.present.pinCode" label="Pincode" readonly={isNextActive} />
              </Grid>
          
            <Grid container item spacing={2} mb={1}>
            <Grid item md={6} xs={10} lg={4}  >
                <FormInputDropdown  rules={{
                      required:
                        CustomerRules.commonValidation("No of years"),
                    }} name="addressDetails.present.noOfYears" control={control} label="No of years" options={years} readonly={isNextActive} />
              </Grid>
              <Grid item md={6} xs={10} lg={4} >
              <FormInputDropdown  rules={{
                      required:
                        CustomerRules.commonValidation("No of months"),
                    }} name="addressDetails.present.noOfMonths" control={control} label="No of months" options={months} readonly={isNextActive}  defaultValue={0} />
              </Grid>
              <Grid item md={12} xs={12} lg={12} >
      <div style={{
        fontSize: "15px",
        color: "#6b778c",
        fontFamily: "Roboto",
        fontWeight: "400",marginBottom:'4px'
      
      }}>Is permanent address same as present address</div>
      <Segmented options={['Yes', 'No']}   
      style={{backgroundColor:"#e0e0e0"}}
      className="custom-segmented"
      onChange={(checked)=>{
        console.log('askdnsakdas',checked);
        
        onChangeSwitch( checked=="Yes"?true:false)
      }} defaultValue={getValues("addressDetails.permanentSameAsPresent")==true?"Yes":"No"} />
             {/* <Switch className='' checkedChildren="Yes" unCheckedChildren="No"  defaultValue={getValues("addressDetails.permanentSameAsPresent")==true?true:false} onChange={(checked)=>{
               onChangeSwitch(checked)
             }} /> */}
             </Grid>

              {/* <Grid item md={12} xs={12} lg={12} >
                <FormInputCheckbox control={control} name="addressDetails.permanentSameAsPresent" label="check if same as permenant address" readOnly={isNextActive} onChangeHandler={onChangeHandler}
               
                />
              </Grid> */}
            </Grid>
        {checkSwitch==false && <>
          <div style={{
        fontSize: "16px",
        color: "#23286c",
        fontFamily: "Roboto",
        fontWeight: "600",marginBottom:'4px',
        marginLeft:'14px',
        marginTop:'14px'
        // textDecorationLine:'underline'
      
      }}>Permanent Address</div>
            <Grid container item spacing={2} mb={1}>
              <FormRow control={control}  rules={{
                      required:
                        CustomerRules.commonValidation("House Number"),
                    }} name="addressDetails.permanent.houseNumber" label="House Number" readonly={isNextActive} />
              <FormRow control={control}  rules={{
                      required:
                        CustomerRules.commonValidation("Address1"),
                    }} name="addressDetails.permanent.addressLine1" label="Address1" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRow control={control}   name="addressDetails.permanent.addressLine2" label="Address2" readonly={isNextActive} />
              <FormRow
              
              rules={{
                required:
                  CustomerRules.commonValidation("City/Town/Village"),
              }}control={control} name="addressDetails.permanent.cityVillageTown" label="City/Town/Village" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <FormRow control={control}  rules={{
                      required:
                        CustomerRules.commonValidation("District"),
                    }} name="addressDetails.permanent.district" label="District" readonly={isNextActive} />
              <FormRow control={control}  rules={{
                      required:
                        CustomerRules.commonValidation("Landmark"),
                    }} name="addressDetails.permanent.landmark" label="Landmark" readonly={isNextActive} />

            </Grid>
            <Grid container item spacing={2} mb={1}>
              <Grid item md={6} xs={10} lg={4} >
                <FormInputDropdown
                rules={{
                  required:
                    CustomerRules.commonValidation("State"),
                }} name="addressDetails.permanent.state" control={control} label="State" options={renderCommonList('states')} readonly={isNextActive} />
              </Grid>
              <FormRow control={control}  inputType="number" rules={CustomerRules.pinCode} name="addressDetails.permanent.pinCode" label="Pincode" readonly={isNextActive} />
            </Grid>
            <Grid container item spacing={2} mb={1}>
              <Grid item md={6} xs={10} lg={4}  >
                <FormInputDropdown  rules={{
                      required:
                        CustomerRules.commonValidation("No of years"),
                    }} name="addressDetails.permanent.noOfYears" control={control} label="No of years" options={years} readonly={isNextActive} />
              </Grid>
              <Grid item md={6} xs={10} lg={4} >
              <FormInputDropdown  rules={{
                      required:
                        CustomerRules.commonValidation("No of months"),
                    }} name="addressDetails.permanent.noOfMonths" control={control} label="No of months" options={months} readonly={isNextActive}  defaultValue={0} />
              </Grid>
            </Grid></>}
          </Grid>
          </Box>
          </Fieldset>
          </Box>
        </CardContent>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, pb: 2 }}>
          <Button   variant="contained"
              sx={{ mt: 2, ml: 1 }} onClick={handleBack} >
                Previous
              </Button>
            <Button
              variant="contained"
              sx={{ mt: 2, ml: 1 }}
              type="submit"
            >
              {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
            </Button>
          </Box>
     
      </Card>
    </form>
  );
};


export default AddressDetails;
