import React, { useEffect, useState } from 'react';

import {
    Box,
    Card,
    CardHeader,
    Divider,
    CardContent,
    Grid,
    Button,
    Stack,
    Typography
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { CustomerRules } from 'src/utils/validationsConfig';
import { FormInputText } from '../shared/FormInputText';
import { Fieldset } from 'src/components/shared/Fieldset';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import _ from 'lodash'
import { useSelector } from 'react-redux';
import { FormInputDropdown } from '../shared/FormInputDropdown';
import CommonApplicantDetails from './common-applicant-details';
import moment from 'moment';
function FormRow(props) {
    const { control, name, label, rules, inputType, readonly,setValue } = props;
    return (
        <React.Fragment>
            <Grid item
                md={6}
                xs={10}
                lg={4}
            >
                <FormInputText
                    name={name}
                    control={control}
                    label={label}
                    rules={rules}
                    inputType={inputType || "text"}
                    readonly={readonly || false}
                    helperText={props.helperText || null}
                    // setValue={setValue}
                />
            </Grid>
        </React.Fragment>
    );
}

const IncomeDetailsLoan = ({
    handleBack,
    handleNext,
    activeStep,
    steps,
    setloanInfo,
    loanInfo,
    isNextActive,
    ApplicantName,
    customerDetail
}) => {
  console.log('asdkaskdnkasnkds',loanInfo);
  
    const globalConfiguration = useSelector(
        (state) => state?.globalConfiguration?.data
      );
    const defaultValuesApplicant = {
        "income.applicant.totalIncome":"",
        "income.applicant.annualIncome":"",
        "income.applicant.otherIncome":"",
        "expenses.applicant.housing":"",
        "expenses.applicant.electricityBill":"",
        "expenses.applicant.education":"",
        "expenses.applicant.healthcare":"",
        "expenses.applicant.debtRepayment":"",
        "expenses.applicant.personal":"",
        "expenses.applicant.businessDevelopment":"",
        "expenses.applicant.transportation":"",
        "expenses.applicant.miscellaneous":"",
        "expenses.applicant.otherUtilities":"",
        "expenses.applicant.insurance":"",

        }
            const defaultValuesCoApplicant = {
                "income.coApplicant.totalIncome":"",
                "income.coApplicant.annualIncome":"",
                "income.coApplicant.otherIncome":"",
                "expenses.coApplicant.housing":"",
                "expenses.coApplicant.electricityBill":"",
                "expenses.coApplicant.education":"",
                "expenses.coApplicant.healthcare":"",
                "expenses.coApplicant.debtRepayment":"",
                "expenses.coApplicant.personal":"",
                "expenses.coApplicant.businessDevelopment":"",
                "expenses.coApplicant.transportation":"",
                "expenses.coApplicant.miscellaneous":"",
              "expenses.coApplicant.otherUtilities":"",
        "expenses.coApplicant.insurance":"",}
    
    const [value, setTabValue] = React.useState(1);
    const { handleSubmit, control,watch,setValue,getValues } = useForm({
        defaultValues:{ ...loanInfo},
    values:{...loanInfo}
    });
     const annualIncome =watch('income.applicant.annualIncome');
  const otherIncome = watch('income.applicant.otherIncome');
    const coApplicantRegularIncome = watch("income.coApplicant.annualIncome",0);
    const coApplicantOtherIncome = watch("income.coApplicant.otherIncome",0);
useEffect(() => {
    calculateTotalIncome(annualIncome, otherIncome,'income.applicant.totalIncome');
}, [annualIncome, otherIncome]);
// Function to check both objects
function validateData(onSubmitData) {
    // Check if all keys from applicant are present and non-empty
    const isApplicantValid = _.every(defaultValuesApplicant, (value, key) => {
        
        
      return _.get(onSubmitData,`${key}`) !== undefined && _.get(onSubmitData,`${key}`) !== "";
    });
  
    // Check if all keys from co-applicant are present and non-empty
    const isCoApplicantValid = _.every(defaultValuesCoApplicant, (value, key) => {
      return _.get(onSubmitData,`${key}`) !== undefined && _.get(onSubmitData,`${key}`) !== "";
    });
  
    // Return true if both applicant and co-applicant are valid, otherwise false
    return {defaultValuesApplicant:isApplicantValid ,
        defaultValuesCoApplicant:isCoApplicantValid}
  }

  const filterLoanProduct = (loanType) => {
    let loanTypes = _.map(
        _.get(globalConfiguration, "loanProducts"),
        (value, key) => ({ value: key, label: value })
      );
    let product = _.filter(
        loanTypes,
      (value, index) => value?.value == loanType
    );
    return _.size(product) > 0
      ? _.get(_.head(product), "label")
      : "Loan Product";
  };
    const onSubmit = (data) => {
        
        setloanInfo({
            ...loanInfo, ...data
        })
        const result = validateData(data);
        if(result.defaultValuesApplicant==true && result.defaultValuesCoApplicant==false){
            setTabValue(2);
        }else if(result.defaultValuesApplicant==true && result.defaultValuesCoApplicant==true){
            handleNext();
        }else if(result.defaultValuesApplicant==false && result.defaultValuesCoApplicant==true){
            setTabValue(1);
        }
        
    }
    const handleChange = (event, newValue) => {
        
        
        setTabValue(newValue);
      };
      
      const calculateTotalIncome = (annualIncome, otherIncome,name) => {
        const regular = parseFloat(annualIncome) || 0;
        const other = parseFloat(otherIncome) || 0;
        const total = regular + other;

        // Set the calculated total income to the totalIncome field
        setValue(name, total.toFixed(2));
    };
     useEffect(() => {
        calculateTotalIncome(coApplicantRegularIncome,coApplicantOtherIncome,'income.coApplicant.totalIncome')
     }, [coApplicantRegularIncome,coApplicantOtherIncome])
     
 
   const getNetIncome = (type) =>{
    
    
    return 4343434
   }
   const filterCoApplicant = (id) => {
    
    if(_.size(_.get(customerDetail,'coApplicants'))>0){
    let coApplicants = _.filter(
        _.get(customerDetail,'coApplicants'),
      (value, index) => value?._id == id
    );
    return {coApplicantName:_.size(coApplicants) > 0
      ? _.startCase(coApplicants[0].firstName+' '+coApplicants[0].lastName)
      : "-",coApplicantId:coApplicants[0]?.customerId,
      coApplicantNumber:coApplicants[0]?.mobileNumber
    };

}else{
    return null
}
  };
    return (
        <form onSubmit={handleSubmit(onSubmit)} >
            <Card  sx={{
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      // backgroundColor: "#f9f9fc",
      padding: "16px",
    }}>
            <CardContent style={{paddingTop:"23px"}} >
           <CommonApplicantDetails applicantName={ApplicantName}
           applicantNumber={customerDetail?.mobileNumber} applicantId={customerDetail?.customerId}
           LoanName={filterLoanProduct(loanInfo?.loanProduct)}
           loanCode={loanInfo?.loanProduct}
           coApplicant={filterCoApplicant(loanInfo?.coApplicantIdMongo)}  />
            <Tabs
      value={value}
      onChange={(e,v)=>handleChange(e,v)}
      centered
      aria-label="custom styled tabs"
      TabIndicatorProps={{
        style: {
          display: 'none', // hide the default tab indicator
        },
      }}
      sx={{
        minHeight: 'unset', // reduce overall height
        marginTop:'20px'
      }}
    >
      <Tab
      value={1}
      key={1}
        label="Applicant"
        sx={{
          
          
          backgroundColor: value === 1 ? 'lightgray' : 'white',
          color: value === 1 ? 'blue' : 'black',
          
          padding: '10px 16px',
          minHeight: 'unset', // reduce individual tab height

          border: "1px solid var(--Gray-300,#d0d5dd)",
          borderRadius: "8px",
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
          borderBottomRightRadius: "0px",
          borderTopRightRadius: 0
        }}
      />
      <Tab
       value={2}
       key={2}
        label="Co-Applicant"
        sx={{
            border: "1px solid var(--Gray-300,#d0d5dd)",
            borderRadius: "8px",
    borderTopLeftRadius: "8px",
    borderBottomLeftRadius: "8px",
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
  backgroundColor: value === 2 ? 'lightgray' : 'white',
  color: value === 2 ? 'blue' : 'black',
          padding: '10px 16px',
          minHeight: 'unset', // reduce individual tab height
          marginLeft: '-1px', // remove gap between tabs
        }}
      />
    </Tabs>
   {value==1? 
   <Box key={'tb-2'} padding={2} paddingLeft={'0px'}>
            
            <Fieldset
           
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            Applicant Income
          </Stack>
        }
        color="#4984BF"
        titleSize="1.2rem"
        borderWidth={1.5}
        borderRadius={1}
        margin="10px"
        >
        
        <Grid container spacing={2}  sx={{marginLeft:'0px !important',width:"100%",marginTop:"0px !important",padding:"0px !important",}}>
                <Grid
                    container
                    item
                    spacing={1}
                    sx={{marginLeft:'10px !important',width:"100%",marginTop:"16px !important",padding:"0px !important",marginBottom:"16px",
                      // paddingLeft:"20px",
                      marginRight:"20px",
                    }}
                >
                   
                    <FormRow control={control} 
                    inputType="number" 
                    name="income.applicant.annualIncome" label="Regular Income" 
                    rules={{ required: CustomerRules.commonValidation("Regular Income") }} 
                     />
                    <FormRow control={control} inputType="number" name="income.applicant.otherIncome" label="Other Income"
                     rules={{ required: CustomerRules.commonValidation("Other Income") }}  />
                    <FormRow control={control}  
                    name="income.applicant.totalIncome" label="Total Income"  readonly={true} />
                </Grid>
               
               
                
            </Grid>
        
        </Fieldset>
        <Fieldset
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            Applicant Expenses
          </Stack>
        }
        color="#4984BF"
        titleSize="1.2rem"
        borderWidth={1.5}
        borderRadius={1}
        margin="10px"
        >
         <Grid container spacing={2}  sx={{marginLeft:'0px !important',width:"100%",marginTop:"0px !important",padding:"16px !important",paddingLeft:"0px !important"}}>
                <Grid
                    container
                    item
                    spacing={2}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"0px !important",padding:"0px !important",
                         marginRight:"10px"
                    }}
                >
                    <FormRow control={control} inputType="number" name="expenses.applicant.housing" label="Housing" 
                    rules={{ required: CustomerRules.commonValidation("House Rent") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.food" 
                    label="Food" rules={{ required: CustomerRules.commonValidation("Food") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.electricityBill" 
                    label="Electricity" rules={{ required: CustomerRules.commonValidation("Electricity") }}  />
                   
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" 
                    name="expenses.applicant.healthcare" label="Medical" rules={{ required: CustomerRules.commonValidation("Medical") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.debtRepayment" 
                    label="Debt Repayment" rules={{ required: CustomerRules.commonValidation("Debt Repayment") }}  />
                     <FormRow  control={control} inputType="number" name="expenses.applicant.education" label="Education" 
                    rules={{ required: CustomerRules.commonValidation("Education") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mb={1} mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" name="expenses.applicant.businessDevelopment" label="Business Development"
                     rules={{ required: CustomerRules.commonValidation("expenses.applicant.Business Development") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.transportation" label="Transportation" 
                    rules={{ required: CustomerRules.commonValidation("expenses.applicant.Transportation") }}  />
                   <FormRow control={control} inputType="number" name="expenses.applicant.personal" 
                    label="Personal" rules={{ required: CustomerRules.commonValidation("Personal") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mb={1} mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" name="expenses.applicant.miscellaneous" label="Miscellaneous" 
                    rules={{ required: CustomerRules.commonValidation("Miscellaneous") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.insurance" label="Insurance" 
                    rules={{ required: CustomerRules.commonValidation("Insurance") }}  />
                    <FormRow control={control} inputType="number" name="expenses.applicant.otherUtilities" label="Other Utilities" 
                    rules={{ required: CustomerRules.commonValidation("Other Utilities") }}  />
                </Grid>
                
            </Grid>
        
        </Fieldset>
        
        
         </Box>:
    <Box key={'tb-3'} padding={2}>
    <Fieldset
    margin="0px"
     marginTop="0px"
    title={
    <Stack direction="row" alignItems="center" gap={1}>
    Co-Applicant Income
    </Stack>
    }
    color="#4984BF"
    titleSize="1.2rem"
    borderWidth={2}
    borderRadius={1}
    >
    
    <Grid container spacing={2} mt={1}>
                <Grid
                    container
                    item
                    spacing={2}
                    
                >
                   
                    <FormRow control={control} 
                    inputType="number" 
                    name="income.coApplicant.annualIncome" label="Regular Income" 
                    rules={{ required: CustomerRules.commonValidation("Regular Income") }} 
                     />
                    <FormRow control={control} inputType="number" name="income.coApplicant.otherIncome" label="Other Income"
                     rules={{ required: CustomerRules.commonValidation("Other Income") }}  />
                    <FormRow control={control}  
                    name="income.coApplicant.totalIncome" label="Total Income"  readonly={true} />
                </Grid>
               
               
                
            </Grid>
    
    </Fieldset>
    <Fieldset
    margin="0px"
    marginTop="25px"
    title={
    <Stack direction="row" alignItems="center" gap={1}>
    Co-Applicant Expenses
    </Stack>
    }
    color="#4984BF"
    titleSize="1.2rem"
    borderWidth={2}
    borderRadius={1}
    >
    
    <Grid container spacing={2} mt={1}>
    <Grid
                    container
                    item
                    spacing={2}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"0px !important",padding:"0px !important",
                         marginRight:"10px"
                    }}
                >
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.housing" label="Housing" 
                    rules={{ required: CustomerRules.commonValidation("House Rent") }}  />
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.food" 
                    label="Food" rules={{ required: CustomerRules.commonValidation("Food") }}  />
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.electricityBill" 
                    label="Electricity" rules={{ required: CustomerRules.commonValidation("Electricity") }}  />
                   
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" 
                    name="expenses.coApplicant.healthcare" label="Medical" rules={{ required: CustomerRules.commonValidation("Medical") }}  />
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.debtRepayment" 
                    label="Debt Repayment" rules={{ required: CustomerRules.commonValidation("Debt Repayment") }}  />
                     <FormRow  control={control} inputType="number" name="expenses.coApplicant.education" label="Education" 
                    rules={{ required: CustomerRules.commonValidation("Education") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mb={1} mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.businessDevelopment" label="Business Development"
                     rules={{ required: CustomerRules.commonValidation("expenses.coApplicant.Business Development") }}  />
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.transportation" label="Transportation" 
                    rules={{ required: CustomerRules.commonValidation("expenses.coApplicant.Transportation") }}  />
                   <FormRow control={control} inputType="number" name="expenses.coApplicant.personal" 
                    label="Personal" rules={{ required: CustomerRules.commonValidation("Personal") }}  />
                </Grid>
                <Grid
                    container
                    item
                    spacing={2}
                    mb={1} mt={0}
                    sx={{marginLeft:'0px !important',width:"100%",marginTop:"15px !important",padding:"0px !important",
                      marginRight:"10px"
                 }}
                >
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.miscellaneous" label="Miscellaneous" 
                    rules={{ required: CustomerRules.commonValidation("Miscellaneous") }}  />
                     <FormRow control={control} inputType="number" name="expenses.coApplicant.insurance" label="Insurance" 
                    rules={{ required: CustomerRules.commonValidation("Insurance") }}  />
                    <FormRow control={control} inputType="number" name="expenses.coApplicant.otherUtilities" label="Other Utilities" 
                    rules={{ required: CustomerRules.commonValidation("Other Utilities") }}  />
                </Grid>
            </Grid>
    
    </Fieldset>
    
    
    </Box>}
      
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingLeft:"24px",paddingRight:"24px" }}>
               
                   
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            onClick={()=>handleBack()}
                        >
                            Previous
                        </Button>
                   
                     
                        <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 1 }}
                            type="submit"
                        >
                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                        </Button>
                    </Box>
              
                </CardContent>
            </Card>
        </form>
    );
};


export default IncomeDetailsLoan;
