import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Card, CardContent, Box, Stack, Button } from "@mui/material";
import { Collapse, Divider, Image, Modal, notification, Spin, Tabs, theme } from "antd";
import _ from "lodash";
import { Fieldset } from "../shared/Fieldset";
import { useSelector } from "react-redux";
import { services } from "../services/services";
import { useNavigate } from "react-router";
import moment from "moment";
import { useSearchParams } from "react-router-dom";

const CustomerReviewScreen = (props) => {
  const [loading, setloading] = useState(false);
  const globalConfiguration = useSelector((state) => state?.globalConfiguration?.data);
  const [searchParams] = useSearchParams();
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const [previewVisible, setPreviewVisible] = useState(false);
  const previewRef = useRef();
  const [imageUrl, setimageUrl] = useState("");
console.log('sakmdknsakd',props);

  const ViewImage = async (value) => {
    console.log('asldmkaskdsa',value);
    
    try {
      let response = await services.getService(`file/get?key=${value}`);

      if (response?.presignedUrl) {
        console.log('asdsakdnkas',response?.presignedUrl);
        
        window.open(response?.presignedUrl, "_blank");
        // setimageUrl(response?.presignedUrl)
        // setPreviewVisible(true)
      } else {
        console.error("Missing presignedUrl or key", response);
      }
    } catch (err) {
      let errors = err?.data?.results;
      if (errors) {
      }
      throw err;
    }
  };
  const openNotificationWithIcon = (type, message) => {
    notification[type]({
      message: type == "error" ? "Error" : "Success",
      description: type == "error" ? "Something went wrong" : message,
      // duration:10
    });
  };
  function getChangedFieldsWithFullObjects(obj1, obj2) {
    const changes = {};

    for (const key in obj1) {
      if (!_.has(obj2, key)) {
        // Key missing in obj2
        changes[key] = obj1[key];
      } else if (_.isObject(obj1[key]) && !Array.isArray(obj1[key])) {
        // If it's a nested object
        if (["familyDetails", "addressDetails", "references", "bankDetails", "documents"].includes(key)) {
          // Return full object if there are changes
          if (!_.isEqual(obj1[key], obj2[key])) {
            changes[key] = obj1[key];
          }
        } else {
          // Otherwise, return only the changed fields
          const nestedChanges = getChangedFieldsWithFullObjects(obj1[key], obj2[key]);
          if (!_.isEmpty(nestedChanges)) {
            changes[key] = nestedChanges;
          }
        }
      } else if (Array.isArray(obj1[key])) {
        // If it's an array
        if (["familyDetails", "references"].includes(key)) {
          // For specific fields, return the full array if changed
          if (!_.isEqual(obj1[key], obj2[key])) {
            changes[key] = obj1[key];
          }
        } else {
          // For other arrays, return only changes
          if (!_.isEqual(obj1[key], obj2[key])) {
            changes[key] = obj1[key];
          }
        }
      } else {
        // For primitive fields, compare directly
        if (!_.isEqual(obj1[key], obj2[key])) {
          changes[key] = obj1[key];
        }
      }
    }

    // Check for keys that exist in obj2 but not in obj1
    for (const key in obj2) {
      if (!_.has(obj1, key)) {
        changes[key] = obj2[key];
      }
    }

    return changes;
  }
  const onSubmit = (data) => {
    const changedFields = getChangedFieldsWithFullObjects(props?.customerInfo, props?.extraStoreCustomer);
    console.log("sadklaskldklaskdsa", changedFields);

    let customer = { ...changedFields };
    if (_.size(data?.familyDetails) > 0 && changedFields?.familyDetails) {
      customer.familyDetails = _.map(data?.familyDetails, (value) => ({ ...value, age: parseInt(value?.age), income: parseFloat(value?.income) }));
    }
    if (_.size(data?.references) > 0 && changedFields?.references) {
      customer.references = _.map(data?.references, (value) => ({ ...value, age: parseInt(value?.age) }));
    }

    setloading(true);
    if (searchParams.get("coapplicant") && searchParams.get("applicant")) {
      if (customer?.dob) {
        customer.dob = moment(customer.dob).format("YYYY-MM-DD");
      }

      services
        .patchService(`coapplicant/update/${searchParams.get("coapplicant")}`, customer)
        .then((res) => {
          setloading(false);
          openNotificationWithIcon("success", "Co-applicant updated successfully");
          navigate(`/app/applicant/details/${searchParams.get("applicant")}`);
        })
        .catch((err) => {
          setloading(false);
          openNotificationWithIcon("error");
          if (!localStorage.getItem("token")) {
            openNotificationWithIcon("error");
          }
        });
    } else if (searchParams.get("applicant")) {
      if (customer?.dob) {
        customer.dob = moment(customer.dob).format("YYYY-MM-DD");
      }
      customer.applicantId = searchParams.get("applicant");
      services
        .postService(`coapplicant/add`, customer)
        .then((res) => {
          setloading(false);
          openNotificationWithIcon("success", "Co-applicant added successfully");
          navigate(`/app/applicant/details/${searchParams.get("applicant")}`);
        })
        .catch((err) => {
          setloading(false);
          openNotificationWithIcon("error");
          if (!localStorage.getItem("token")) {
            openNotificationWithIcon("error");
          }
        });
    } else if (props?.customerInfo?._id) {
      if (customer?.dob) {
        customer.dob = moment(customer.dob).format("YYYY-MM-DD");
      }
      delete customer?._id;
      services
        .patchService(`applicant/update/${props?.customerInfo?._id}`, customer)
        .then((res) => {
          setloading(false);
          openNotificationWithIcon("success", "Applicant updated successfully");
          navigate("/app/applicants");
        })
        .catch((err) => {
          setloading(false);
          openNotificationWithIcon("error");
          if (!localStorage.getItem("token")) {
            openNotificationWithIcon("error");
          }
        });
    } else {
      services
        .postService(`applicant/add`, customer)
        .then((res) => {
          setloading(false);
          openNotificationWithIcon("success", "Applicant added successfully");
          navigate("/app/applicants");
        })
        .catch((err) => {
          setloading(false);
          openNotificationWithIcon("error");
          if (!localStorage.getItem("token")) {
            openNotificationWithIcon("error");
          }
        });
    }
  };

  const filterLoanProduct = (loanType) => {
    let data = _.map(_.get(globalConfiguration, "loanProducts"), (value, key) => ({ value: key, label: value }));
    let product = _.filter(data, (value, index) => value?.value == loanType);
    return _.size(product) > 0 ? _.get(_.head(product), "label") : "Loan Product";
  };

  const handleCancel = () => {
    setPreviewVisible(false); // Close the modal
  };

  const ViewProfileImage = async (value) => {
    if (value) {
      try {
        const response = await services.getService(`file/get?key=${value}`);
        if (response?.presignedUrl) {
          return { imageUrl: response.presignedUrl, success: true };
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    }
    return null;
  };

  // Render Product
  const renderProduct = (data, type) => {
    let details =
      type == "applicant"
        ? ["firstName", "lastName",  "dob", "gender", "email","mobileNumber",]
        : ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];

    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(_.pick(props?.customerInfo, details), (value, key) => {
            return (
              (value != undefined || value != null) &&
              value && (
                <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{key=="dob"?"DOB": _.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "dob" ? (_.isObject(value) == true ? "Object" : moment(value).format("DD/MM/YYYY")) :key == "email"?value: _.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    const fetchImageData = async () => {
      if (props?.customerInfo?.photo) {
        const data = await ViewProfileImage(props?.customerInfo?.photo);

        setImageData(data);
      }
    };

    fetchImageData();
  }, [props?.customerInfo?.photo]);
  const formatAadhaarNumber = (aadhaarNumber) => {
    // Remove non-numeric characters
    const digits = aadhaarNumber.replace(/\D/g, "");

    // Check if the number is valid (12 digits for Aadhaar)

    // Format the number as XXXX XXXX XXXX
    return `${digits.slice(0, 4)} ${digits.slice(4, 8)} ${digits.slice(8)}`;
  };
  const renderCustomerDetails = (data, type) => {
    let details =
      type == "applicant"
        ? ["firstName", "lastName", "dob", "gender", "email","mobileNumber"]
        : ["spouseName", "fathersName", "mothersName", "education", "maritalStatus", "religion", "caste", "physicallyChallenged", "occupation"];

    // const imageData =props?.customerInfo?.photo ? await ViewProfileImage(props?.customerInfo?.photo):null;
    //

    return (
      <Box sx={{ display: "flex" }}>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(_.pick(props?.customerInfo, details), (value, key) => {
            return (
              (value != undefined || value != null) &&
              value && (
                <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                  {" "}
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{key == "dob"? "DOB":_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "dob"
                        ? _.isObject(value) == true
                          ? moment(value).format("DD/MM/YYYY")
                          : moment(value).format("DD/MM/YYYY")
                        : key == "mobileNumber"
                        ? formatIndianPhoneNumber(value)
                        : key == "aadharId"
                        ? formatAadhaarNumber(value)
                        :key == "email"?value: _.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              )
            );
          })}
        </Grid>
        {imageData?.imageUrl && (
          <Box>
            <div style={{ width: "170px", height: "170px", cursor: "pointer" }}>
              <img style={{ width: "100%", height: "100%" }} src={imageData?.imageUrl} />
              {/* <div style={{fontSize:"12px",marginTop:"10px",color:"#6b778c",textAlign:"center"}}>Upload Profile</div> */}
            </div>
          </Box>
        )}
      </Box>
    );
  };

  const renderBank = (data, type) => {
    return (
      <Box>
        <Grid container spacing={"1px"} padding={"0px"}>
          {_.map(data?.bankDetails, (value, key) => {
            return (
              <Grid item xs={12} key={key} sm={6} md={3} marginBottom={"12px"}>
                {" "}
                <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{}}>
                  <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{key == "dob" ?"DOB":_.startCase(key)}</Typography>
                  <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                     {(key !="ifscCode" && key !="accountNumber")? _.startCase(value):value}
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  //Render Invome and expense
  const renderAddress = (details, type) => {
    let permanent =
      type === "present"
        ? details?.addressDetails?.present
        : details?.addressDetails?.permanent;
  
    const formatAddress = (address) => {
      if (!address) return "N/A";
  
      const {
        houseNumber,
        addressLine1,
        addressLine2,
        cityVillageTown,
        district,
        state,
        pinCode,
        noOfYears,
        noOfMonths,
      } = address;
  
      // Format the duration string
      const duration = [];
      if (noOfYears > 0) duration.push(`${noOfYears} Year(s)`);
      if (noOfMonths > 0) duration.push(`${noOfMonths} Month(s)`);
  
      return [
        houseNumber ? `NO: ${houseNumber}, ${addressLine1},` : null,
        addressLine2 ? `${addressLine2},` : null,
        cityVillageTown ? `${cityVillageTown},` : null,
        district ? `${district},` : null,
        state || pinCode ? `${state ? state : ""} - ${pinCode ? pinCode : ""}.` : null,
        duration.length > 0 ? `${duration.join(" and ")}` : null,
      ].filter(Boolean); // Removes null or undefined values
    };
  
    return (
      permanent && (
        <Box>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#6b778c",
              textDecorationLine: "underline",
              // marginBottom: "15px",
            }}
          >
            {_.startCase(type)}
          </Typography>
          <Grid container spacing={2} sx={{marginLeft:"1px",marginTop:"10px"}}>
            <Box
              display="flex"
              alignItems="flex-start"
              flexDirection="column"
              sx={{ marginRight: "16px", marginBottom: "16px" }}
            >
              {formatAddress(permanent).map((line, index) => (
                <Typography
                  key={index}
                  sx={{ fontSize: "16px", fontWeight: "500", color: "#000" }}
                >
                  {line}
                </Typography>
              ))}
            </Box>
          </Grid>
        </Box>
      )
    );
  };
  
  
  const formatIndianPhoneNumber = (phoneNumber) => {
    // Remove non-numeric characters
    const digits = phoneNumber.replace(/\D/g, "");

    // Format the number as 987-654-3210
    return `+91 ${phoneNumber}`;
  };
  const renderFamily = (details, type) => {
    const fieldsToRender = [
      "name",
      "age",
      "gender",
      "relationship",
      "occupation",
      "income",
      "mobileNumber",
      "address",
    ];
    const formattedData = details?.map((item) => _.pick(item, fieldsToRender));
   
    return _.map(formattedData, (item, index) => {
      return (
        <Box key={index}>
          <Grid container spacing={2}>
            {_.map(item, (value, key) => {
              console.log('dsijfidsjfsdsdisd',value,key);
              
              return key!="aadharId" && value &&value !=0 && (
                 <Grid item xs={12} key={key} sm={6} md={2}>
                  <Box display="flex" alignItems="flex-start" flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "16px" }}>
                    <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>{_.startCase(key)}</Typography>
                    <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                      {key == "income"
                        ? `₹${formatIndianNumber(parseFloat(value))}`
                        : key == "mobileNumber"
                        ? formatIndianPhoneNumber(value)
                        : _.isObject(value) == true
                        ? "Object"
                        : key == "address"?value:_.startCase(value)}
                    </Typography>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          {_.size(details) != index + 1 && <Divider />}
        </Box>
      );
    });
  };

  //render Document
  const renderDocument = (data) => {
    return (
      <>
        {_.map(data?.documents, (item, key, index) => {
          return (
            <Grid container key={index} sx={{ display: "flex", flexDirection: "column" }}>
              <Box>
                <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000", textDecorationLine: "underline", marginBottom: "15px" }}>
                  {_.startCase(key)}
                </Typography>
              </Box>
              <Box sx={{flexDirection:'row',display:'flex'}}>
              <Box sx={{ display: "flex",flexDirection:'column' }}width={"100%"} >
                {_.map(item, (v, i, index) => {
                  delete v?.documentUpload;
return <Box key={index} flexDirection={"row"} display={"flex"} >
                  { _.map(v, (items, i) => {
                    return (
                      <Box key={i} display={"flex"} flexDirection={"row"} width={"100%"}>
                        <Grid item  sm={6} md={3} width={"100%"} display={"flex"} flexDirection={"row"}>
                        {" "}
                        <Box display="flex" alignItems="flex-start"  flexDirection={"column"} sx={{ marginRight: "16px", marginBottom: "8px" }}>
                          <Typography sx={{ fontSize: "13px", fontWeight: "400", color: "#6b778c" }}>
                            {i == "url" ? "Attachment" : _.startCase(i)}
                          </Typography>
                          {i == "url" ? (
                            <Typography
                              component={"span"}
                              onClick={() => {
                                ViewImage(items);
                              }}
                              sx={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#00000",
                                boxShadow: "0 4px 8px #0000001a",
                                border: "1px solid #eaecf0",
                                padding: "2px 12px",
                                borderRadius: "7px",
                                cursor: "pointer",
                              }}
                            >
                              View
                            </Typography>
                          ) : (
                            <Typography sx={{ fontSize: "16px", fontWeight: "500", color: "#00000" }}>
                              {i == "type" ? _.startCase(items) : items}
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      </Box>
                    );
                  })}
                  </Box>
                })}
              </Box>
              </Box>
            </Grid>
          );
        })}
      </>
    );
  };

  function formatIndianNumber(value) {
    if (!_.isNumber(value)) return value;

    const number = _.toString(value);
    const lastThree = number.slice(-3);
    const rest = number.slice(0, -3);

    return rest.length > 0 ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
  }

  const getItems = (panelStyle) => [
    {
      key: "1",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Applicant Details</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(0);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderCustomerDetails(props?.customerInfo, "applicant"),
      style: panelStyle,
    },
    {
      key: "2",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Personal Info</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(1);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: (
        <div>
          <div>{renderProduct(props?.customerInfo)}</div>
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "3",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Address</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(2);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: (
        <div>
          {renderAddress(props?.customerInfo, "present")}
          {props?.customerInfo?.addressDetails?.permanentSameAsPresent==false && renderAddress(props?.customerInfo, "Permanent")}
        </div>
      ),
      style: panelStyle,
    },
    {
      key: "4",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Family Details</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(3);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderFamily(props?.customerInfo?.familyDetails),
      style: panelStyle,
    },
    {
      key: "5",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Bank</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(4);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderBank(props?.customerInfo),
      style: panelStyle,
    },
    {
      key: "6",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Reference Details</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(5);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderFamily(props?.customerInfo?.references),
      style: panelStyle,
    },
    {
      key: "7",
      label: (
        <div
          style={{
            fontSize: "16px",
            fontWeight: "500",
            display: "flex",
            alignItems: "center",
          }}
        >
          <span>Documents Details</span>{" "}
          <span
            style={{ width: "24px", height: "24px", marginLeft: "16px", cursor: "pointer" }}
            onClick={() => {
              props?.goToStep(6);
            }}
          >
            <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
      ),
      children: renderDocument(props?.customerInfo),
      style: panelStyle,
    },
  ];
  const onChange = (key) => {};
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
  };

  const convertAdharSpace = (number) => {
    const result = _.chunk(number.toString(), 4)
      .map((chunk) => chunk.join("")) // Join each chunk back into a string
      .join(" ");

    return result;
  };

  return (
    <Spin spinning={loading}>
      {" "}
      <Card
        sx={{
          background: "#fff",
          border: "1px solid #eaecf0",
          borderBottom: "none !important",
          borderRadius: "8px",
          boxShadow: "0 1px 3px 0 #1018281a,0 1px 2px 0 #1018280f",
          marginBottom: "24px !important",
          padding: "0px",
        }}
      >
        <CardContent sx={{ padding: "0px" }}>
          <Modal open={previewVisible} footer={null} onCancel={handleCancel} centered>
            <Image
              preview={false}
              src={imageUrl} // Replace with your image URL
              alt="Preview"
            />
          </Modal>
          <Box sx={{ margin: "24px" }}>
            <Collapse
              defaultActiveKey={["1", "2", "3", "4"]}
              onChange={onChange}
              items={getItems(panelStyle)}
              style={{
                border: "none",
                background: "#fff",
              }}
            />

            <Box sx={{ display: "flex", justifyContent: "space-between", px: 2, pb: 2 }}>
              {" "}
              {props?.activeStep !== 0 && (
                <Button variant="contained" onClick={() => props?.handleBack()} sx={{ mt: 2, ml: 1 }}>
                  Previous
                </Button>
              )}
              <Button
                onClick={() => {
                  onSubmit(props?.customerInfo);
                }}
                variant="contained"
                sx={{ mt: 2, ml: 1 }}
              >
                {props?.customerInfo?._id ? "Update" : "Create"}
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Spin>
  );
};

export default CustomerReviewScreen;
