const { Grid, Stack, Box, Typography, Divider } = require("@mui/material")
const { Fieldset } = require("../shared/Fieldset")


const CommonApplicantDetails = ({applicantName,applicantId,applicantNumber, applicantDob,coApplicant,loanCode,LoanName}) => {

  function formatIndianPhoneNumber(phoneNumber) {
    // Remove any non-digit characters
    const cleaned = phoneNumber?.replace(/\D/g, "");
  
    // Check if the length is valid for an Indian number
    return `+91 ${cleaned}`
  }
       return  <Grid container spacing={2} sx={{marginLeft:'0px',width:"100%"}}>
       <Fieldset
               title={
                 <Stack direction="row" alignItems="center" gap={1}>
                   Applicant Details
                 </Stack>
               }
               color="#4984BF"
               titleSize="1.2rem"
               borderWidth={1.5}
               borderRadius={1}
               margin="10px"
             >
       <div  style={{
         padding:'16px',
         paddingBottom:'10px',
         paddingTop:"26px",
         display:'flex',
         width:"100%"
       }}>
  <Box display="flex" flexDirection="column" width={"100%"} >
<Box flexDirection={"row"} display={"flex"} width={"100%"}>
<Box display="flex" alignItems="flex-start"  flexDirection={'column'} width={"16%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
         Applicant Name:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
         {applicantName}
       </Typography>
     </Box>
     {/* {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"20%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
       Applicant Id:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
      {applicantId}
       </Typography>
     </Box>} */}
     {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"16%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
      Phone Number:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
      {formatIndianPhoneNumber(applicantNumber)}
       </Typography>
     </Box>}
     <Box display="flex" alignItems="flex-start"  flexDirection={'column'} width={"16%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
        Product Name:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
         {LoanName}
       </Typography>
     </Box>
     {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"16%"} >
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
       Product Code:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
      {loanCode}
       </Typography>
     </Box>}
</Box>
<Divider style={{width:"100%",marginTop:'10px',marginBottom:"10px"}}/>
<Box flexDirection={"row"} display={"flex"} >
<Box display="flex" alignItems="flex-start"  flexDirection={'column'} width={"16%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
         Co-Applicant Name:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
         {coApplicant?.coApplicantName}
       </Typography>
     </Box>
     {/* {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"20%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
       Co-Applicant Id:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
       {coApplicant?.coApplicantId}
       </Typography>
     </Box>} */}
     {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"16%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
       Phone Number:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
      {formatIndianPhoneNumber(coApplicant?.coApplicantNumber)}
       </Typography>
     </Box>}
     
</Box>


     
  </Box>
  {/* <Box flexDirection={"row"} display={"flex"} width={"40%"} >
<Box display="flex" alignItems="flex-start"  flexDirection={'column'} width={"25%"}>
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
        Product Name:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
         {applicantName}
       </Typography>
     </Box>
     {<Box display="flex" alignItems="flex-start" flexDirection={'column'} width={"25%"} >
       <Typography sx={{ fontSize: '13px', fontWeight: '400', color: "#6b778c" }}>
       Product Code:
       </Typography>
       <Typography sx={{ fontSize: '16px', fontWeight: '500', color: "#00000",marginTop:"4px" }}>
      {applicantId}
       </Typography>
     </Box>}
     




     
  </Box> */}

   </div>

   
   </Fieldset></Grid>
}

export default CommonApplicantDetails;