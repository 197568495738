import { useState } from 'react';
// import PropTypes from 'prop-types';
// import moment from 'moment';
// import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Chip,
  Stack,
  TableContainer,
  Button,
} from '@mui/material';
import getInitials from '../../utils/getInitials';
// import { Link } from 'react-router-dom';
import globalConfig from '../../utils/config';

import _ from 'lodash'
import PaymentIcon from '@mui/icons-material/Payment';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
// import MoneyIcon from '@mui/icons-material/Money';
// import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import DialogBox from '../shared/DialogBox';
import { services } from '../services/services';
import { Pagination } from 'antd';
// import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
// import DetailsTwoToneIcon from '@mui/icons-material/DetailsTwoTone';
// import { Helmet } from 'react-helmet';
import PreviewIcon from '@mui/icons-material/Preview';

const LoansListResults = ({ loans, onChangePage, pagination_details, type_loan = "loan", ...rest }) => {

  
  // const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [open, setOpen] = useState(false);
  const [customerId, setCustomerId] = useState(0);
  // const [openAlert, setOpenAlert] = useState(false);
  const [page1, setPage1] = useState(0);
  
  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    
    onChangePage(page1, event.target.value)
  };

  const handlePageChange = (pageNumber, newPage) => {
    
    
    setPage(pageNumber);
    // setPage1(page < newPage ? newPage + 10 : newPage - 10)
    onChangePage(pageNumber, limit)
  };
  // const handleDeleteCustomer = (customerId) => {
  //   setOpen(true);
  //   setCustomerId(customerId);
  // }
  const onConfirm = () => {
    setCustomerId(0)
    setOpen(false);
    services.putService(`customer/delete/${customerId}`)
      .then(res => {
        
        // setOpenAlert(true);
      })
      .catch(err => err);
  }

   function formatIndianNumber(value) {
      if (!_.isNumber(value)) return value;
  
      const number = _.toString(value);
      const lastThree = number.slice(-3);
      const rest = number.slice(0, -3);
  
      return rest.length > 0 ? rest.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + lastThree : lastThree;
    }
  return (
    <Card {...rest}>

      <Box>


        <TableContainer style={{ maxHeight: "550px", overflowX: 'auto' }}>
          <Table stickyHeader >
            <TableHead >
              <TableRow>
                <TableCell>
                  Applicant Id
                </TableCell>
                <TableCell>
                  Co-Applicant Id
                </TableCell>
                <TableCell>
                Loan Product
                </TableCell>
                <TableCell>
                Loan Amount
                </TableCell>
                <TableCell>
                  Tenure
                </TableCell>
                {/* <TableCell>
                  Disbursed On
                </TableCell> */}
                <TableCell>
                  Status
                </TableCell>
                <TableCell>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {_.size(loans) > 0 ? loans.slice(0, limit).map((loan) => (
                <TableRow
                  hover
                  key={loan.id}
                >
                  
                      <TableCell>
                        {loan.applicationId}
                      </TableCell>
                  
                  <TableCell>
                    {loan.coApplicant}
                  </TableCell>
                  <TableCell>
                    {loan.loanProduct}
                  </TableCell>
                  <TableCell>
                  ₹{formatIndianNumber(parseFloat(loan.loanAmount))}
                  </TableCell>
                  <TableCell>
                  {loan.loanTenure} Months
                  </TableCell>
                  {/* <TableCell>
                  {loan.expectedDate}
                  </TableCell> */}

                  <TableCell>
                    {_.startCase(loan.status)}
                  </TableCell>
                  
                  <TableCell>
                    <div style={{display:'flex'}}>
                    <Button disableRipple disableFocusRipple href={`loan/details/${loan._id}?tab=loan`} variant="outlined" 
                     startIcon={<PreviewIcon style={{fill:'rgb(71, 84, 103)'}}   />}
                    sx={{borderRadius:'20px',color:"rgb(71, 84, 103)",height:'30px',textTransform:"none",border:"1px solid rgb(71, 84, 103)",
                    marginRight:'5px',marginBottom:'5px'}}>
                    View</Button>
                    </div>
                      {/* <Chip label="Details" size="small" component="a" href={`loan/details/${loan.loan_id}?type=${type_loan}`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="EMI" size="small" component="a" href={`emi/${loan.loan_id}?type=${type_loan}`} clickable icon={<VisibilityIcon />} color="secondary" />
                      <Chip label="PAY" size="small" component="a" href={`payment/${loan.loan_id}?type=${type_loan}`} clickable icon={<PaymentIcon />} color="secondary" /> */}
                
                  </TableCell>
                </TableRow>
              )) : <TableRow ><TableCell style={{ alignItems: 'center', padding: '70px', justifyContent: 'center', textAlign: 'center', fontSize: 18, fontWeight: 'bolder', marginBottom: '44%' }} colSpan={9}>No Loans</TableCell></TableRow>}

            </TableBody>
          </Table></TableContainer>
      </Box>
      <div style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "22px",
            marginBottom:'20px'
          
           }}>
           <Pagination
            onChange={handlePageChange}
            showSizeChanger={false}
            defaultPageSize={10}
            // onShowSizeChange={handleLimitChange}
            // defaultCurrent={5}
            total={_.get(pagination_details, 'total', 10)}
          />
           </div>
      <DialogBox open={open} setOpen={setOpen} onConfirm={onConfirm} text="Delete the Customer, Are you sure?" />
    </Card>
  );
};

export default LoansListResults;
