import React from 'react';
import { Controller } from "react-hook-form";
import { useState } from 'react';
import _ from 'lodash'
import { useEffect } from 'react';
import { Button } from '@mui/material';
import BorderColorSharpIcon from '@mui/icons-material/BorderColorSharp';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone';
import { FaEye } from "react-icons/fa";
export const DocumentUploadFile = (props) => {
  const hiddenFileInput = React.useRef(null);
  const [uploads, setUploads] = useState({}); // State to track multiple uploads

  const { name, control, rules, disabled = false, id } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  useEffect(() => {
    if (_.get(props, 'value')) {
      setUploads(prevState => ({
        ...prevState,
        [id]: _.get(props, 'value')
      }));
    }
  }, [id, props.value]);
  const renderWithDots = (text) => {
       const limitedWords = `${text}`.slice(0, 27); // Get the first 50 words
       return limitedWords.toString(' ') + (limitedWords.length > 26 ? '...' : '');
     };
     console.log("Test flowsds",fieldRules);
     
  return (
    <>
      <Controller
      rules={fieldRules}
        name={name}
        control={control}
        render={({
          field: { onChange },
          fieldState: { error },
        }) => (
         <div>
           <div style={props?.defaultValue?.name?{
              display:'flex',
              alignItems:'center'
          }:error?.message?{marginBottom:'4px'}: {}}>
            {console.log('asmdknkaskdnksad',error)
            }
            {props?.defaultValue?.name ? (
              <div style={{alignItems:'center',cursor:'pointer',marginTop:"1px",display:'flex',justifyContent:'center'}} >
                {/* <div style={{marginRight:'20px',cursor:'pointer',display:'flex',alignItems:'center'}}>
                  <FileDownloadDoneIcon color='red' fontSize='12px' style={{fill:"#2ab72a",width:"18px",height:'18px'}} /><span style={{fontSize:"12px"}}>uploaded</span> <span onClick={()=>{
                    props?.onclickView()
                  }} style={{textDecorationLine:"underline",fontSize:"12px",marginLeft:"5px"}}>View</span>
              
                  </div> */}
                  <Button variant="outlined"  style={{height:'40px',color:"#6b778c",border:"1px solid #6b778c",marginRight:'14px'}}   startIcon={<FaEye /> } onClick={()=> props?.onclickView()}>
                  View</Button>
                <Button style={{height:'40px'}} variant="outlined"  startIcon={<CloudUploadIcon /> } onClick={handleClick}>
                Upload</Button>
                
              {/* <CloudDoneIcon /> */}
                {/* <BorderColorSharpIcon /> */}
              </div>
            ) : (
              <Button style={{height:'40px'}} variant="outlined" onClick={handleClick} startIcon={<CloudUploadIcon /> }>
  Upload file
</Button>
            )}
            <input
              ref={hiddenFileInput}
              accept="image/*"
              style={{ display: 'none' }}
              type={'file'}
              name="Select Image"
              disabled={props?.disabled}
              onChange={(event) => {
                if (!disabled) {
                  const file = event.target.files[0];
                  setUploads(prevState => ({
                    ...prevState,
                    [id]: { file, type_name: name, id }
                  }));
                  onChange(file);
                  props.newFieldTru(file);
                }
              }}
            />
            
          </div>
          {error?.message && <span style={{color:"#d32f2f",fontSize:"0.75rem",
              lineHeight:"1.66"
            }}>{error?.message}</span>}
         </div>
        )}
        
      />
    </>
  );
};

export const UserProfileCardImage = (props) => {
  const hiddenFileInput = React.useRef(null);
  const [uploads, setUploads] = useState({}); // State to track multiple uploads

  const { name, control, rules, disabled = false, id } = props;
  let fieldRules = rules || {};
  if (rules === undefined) fieldRules.required = false;

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  console.log("ajjshdjkhaskdhjkas",props?.defaultValue);
  
 
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={ ({
          field: { onChange },
          fieldState: { error },
        }) => (
          <div style={props?.defaultValue?.name?{
              display:'flex',
              alignItems:'center'
          }:{}}>
            
            {props?.defaultValue?.name ? (
              <div className="row" style={{display:'flex',alignItems:'center',cursor:'pointer',marginTop:'7px'}} onClick={handleClick}>
                
                <Button style={{height:'40px'}} variant="outlined"  startIcon={<CloudDoneIcon /> }>
  Uploaded
</Button>
                <div style={{marginRight:'20px',cursor:'pointer'}}>
                  
                 
                  </div>
              
              </div>
            ) :props?.defaultValue?.imageUrl?<div style={{width:'170px',height:"170px",cursor:'pointer'}} onClick={handleClick}>
            <img style={{width:"100%",height:"100%"}}  src={`${props?.defaultValue?.imageUrl}`} loading="lazy"
             onError={(e) => {
              e.target.onerror = null;
              e.target.src =`${props?.defaultValue?.imageUrl}`; // Replace with your fallback image
            }}
            />
            <div style={{fontSize:"12px",marginTop:"10px",color:"#6b778c",textAlign:"center"}}>Upload Profile</div>
          </div>:  (
              <div style={{width:'170px',height:"170px",cursor:'pointer'}} onClick={handleClick}>
                <img   style={{width:"100%",height:"100%"}} src={require('../../assets/images/user-profile-1.png')} />
                <div style={{fontSize:"12px",marginTop:"10px",color:"#6b778c",textAlign:"center"}}>Upload Profile</div>
              </div>
            )}
            <input
              ref={hiddenFileInput}
              accept="image/*"
              style={{ display: 'none' }}
              type={'file'}
              name="Select Image"
              disabled={props?.disabled}
              onChange={(event) => {
                if (!disabled) {
                  const file = event.target.files[0];
                  // setUploads(prevState => ({
                  //   ...prevState,
                  //   [id]: { file, type_name: name, id }
                  // }));
                  onChange(file);
                  props.newFieldTru(file);
                }
              }}
            />
          </div>
        )}
        rules={fieldRules}
      />
    </>
  );
};
